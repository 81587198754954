import React, { Fragment } from 'react';
import { Box, TextField, BoxProps, TextFieldProps } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FormArrComponent = ({
  fields,
  boxProps,
}: {
  fields: Array<
    Array<{
      label: string;
      name: string;
      checkbox?: boolean;
      textFieldProps?: TextFieldProps;
      options?: Array<{ label: string; value: string }>;
    }>
  >;
  boxProps?: BoxProps;
}) => {
  const inputDefault = (field) => (
    <TextField label={field.label} {...field.textFieldProps}>
      {field.options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );

  const inputCheckbox = (field) => (
    <FormGroup>
      <FormControlLabel control={<Checkbox />} label={field.label} />
    </FormGroup>
  );

  const fieldDefault = (field) => [field.checkbox].every((f) => !f);

  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      {fields.map((f, index) => (
        <Box
          key={`field-${index}`}
          display="grid"
          gridTemplateColumns={`repeat(${f.length}, 1fr)`}
          gap={boxProps?.gap}
          alignItems="center"
        >
          {f.map((field) => (
            <Fragment key={field.name}>
              {field.checkbox && inputCheckbox(field)}
              {fieldDefault(field) && inputDefault(field)}
            </Fragment>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default FormArrComponent;
