import React from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadingButton as Button } from '@mui/lab';
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import {
  Box,
  Divider,
  Typography,
  Tabs,
  Tab,
  Avatar,
  IconButton,
  TextField,
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import format from 'date-fns/format';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import toCurrencyUtil from '@spot-spotesg/utils/toCurrency.util';
import toDateHourUtil from '@spot-spotesg/utils/toDateHour.util';
import toDecimalUtil from '@spot-spotesg/utils/toDecimal.util';
import toNumberUtil from '@spot-spotesg/utils/toNumber.util';

import messages from '@spot/shared-constants/messagesSchema.constant';

import FieldUploadPhoto from '@spot-spotesg/components/FieldUpload.component';

import AppLayout from '@spot-spotesg/layouts/App.layout';

import FormArrFormikComponent from '@spot-spotesg/components/FormArrFormik.component';
import BoxWhite from '@spot-spotesg/components/Box/BoxWhite.component';
import TableComponent from '@spot-spotesg/components/Table.component';

import useAuth from '@spot/shared-hooks/useAuth.hook';

import PROFILE_ROUTES from '../constants/profileRoutes.constant';

import user from '../store/currentUser';
import usersState from '../store/users';
import config from '../store/config';

const BoxInvoice = ({ label, amount, currency = false }) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography variant="subtitle2" color="gray.300">
        {label}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={currency ? 700 : 500}
        color="secondary.main"
        sx={{
          padding: currency ? '4px 8px' : undefined,
          bgcolor: currency ? 'info.light3' : undefined,
          borderRadius: 0.5,
          alignSelf: 'start',
        }}
      >
        {amount}
      </Typography>
    </Box>
  );
};

const ProfileScreen = () => {
  const [invoiceMonth, setInvoiceMonth] = React.useState(new Date());
  const [hasPhoto, setHasPhoto] = React.useState<any>(false);
  const { user: userToken } = useAuth();

  const history = useHistory();

  const dispatch = useDispatch();

  const selectorRedux = {
    dataUser: useSelector(user.selector.selectData),
    dataUsers: useSelector(usersState.selector.selectData),
    dataResultsUsers: useSelector(usersState.selector.selectDataResults),
    dataMetadataUsers: useSelector(usersState.selector.selectDataMetadata),
    loadingUser: useSelector(user.selector.selectLoading),
    loadingUsers: useSelector(usersState.selector.selectLoading),
    tab: useSelector(config.selector.selectTab),
  };

  const dispatchRedux = {
    resetStateUsers: flow(usersState.action.resetState, dispatch),
    servicePatchUser: flow(user.action.servicePatch, dispatch),
    updateTab: flow(config.action.updateTab, dispatch),
    resetState: flow(config.action.resetState, dispatch),
  };

  const isStaff = userToken?.user.profile_type === '2';

  const currentTab = selectorRedux.tab;
  const setCurrentTab = flow(dispatchRedux.updateTab);

  React.useEffect(() => {
    return () => {
      dispatchRedux.resetStateUsers();
    };
  }, []);

  React.useEffect(() => {
    if (selectorRedux.dataUser?.user_photo) {
      setHasPhoto(selectorRedux.dataUser?.user_photo);
    }
  }, [selectorRedux.dataUser]);

  const profile = (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        name: userToken?.user?.name,
        email: userToken?.user?.email,
        password: '',
        password_confirmation: '',
      }}
      onSubmit={async (values) => {
        dispatchRedux.servicePatchUser({
          user_id: userToken?.user?.id,
          ...values,
        });

        return true;
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(messages.REQUIRED),
        email: Yup.string().email(messages.EMAIL).required(messages.REQUIRED),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            messages.PASSWORD_SPECS
          )
          .oneOf(
            [Yup.ref('password_confirmation'), ''],
            'As senhas não são iguais'
          ),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref('password'), ''],
          'As senhas não são iguais'
        ),
      })}
    >
      {({ submitForm, errors, values, setFieldValue }) => (
        <Form key={`profile-form-1-${selectorRedux.dataUser}`}>
          <Box
            display="grid"
            gridTemplateColumns="100%"
            gap={4}
            alignItems="flex-start"
          >
            <Box display="flex" flexDirection="column" gap={4}>
              <FormArrFormikComponent
                boxProps={{
                  gap: 4,
                }}
                fields={[
                  [
                    {
                      label: 'Nome',
                      name: 'name',
                    },
                  ],
                  [
                    {
                      label: 'E-mail',
                      name: 'email',
                    },
                  ],
                  [
                    {
                      label: 'Senha',
                      name: 'password',
                      variant: 'password',
                    },
                    {
                      label: 'Confirmar senha',
                      name: 'password_confirmation',
                      variant: 'password',
                    },
                  ],
                ]}
              />

              <Box alignSelf="end">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  sx={{ color: 'white' }}
                  type="submit"
                  loading={selectorRedux.loadingUser}
                  disabled={
                    Object.keys(errors).length > 0 || selectorRedux.loadingUser
                  }
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );

  const tabs = [
    {
      label: 'PERFIL',
      value: 1,
    },
  ].filter((t) => !!t);

  return (
    <AppLayout title="Configurações">
      <Box display="flex" flexDirection="column" gap={3}>
        <BoxWhite loading={[selectorRedux.loadingUser].some((l) => l)}>
          <Box display="flex" flexDirection="column" gap={3}>
            {/* <Box mx={-3} mt={-3}>
              <Tabs
                centered
                value={currentTab}
                onChange={(_, val) => setCurrentTab(val)}
                sx={{
                  button: {
                    flex: 1,
                    maxWidth: 'none',
                  },
                }}
              >
                {tabs.map((t: any) => (
                  <Tab
                    label={t.label}
                    value={t.value}
                    key={t.value}
                    disabled={t?.disabled}
                  />
                ))}
              </Tabs>
            </Box> */}

            {currentTab === 1 && profile}
          </Box>
        </BoxWhite>
      </Box>
    </AppLayout>
  );
};

export default ProfileScreen;
