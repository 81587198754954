import { createReducer } from '@reduxjs/toolkit';

import initialState from './mapDualLeft.initialState';

import { ACTION_TYPES } from './mapDualLeft.constant';

const handleReset = () => initialState;

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchEnd = (state, action) => {
  state.loading = false;
  state.data = action.payload;
  state.error = [false, null];
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
  state.data = initialState.data;
};

const handleResetBounds = (state) => {
  state.bounds = initialState.bounds;
};

const handleUpdateFilters = (state, action) => {
  state.filters = action.payload;
};

const handleUpdateTile = (state, action) => {
  state.tile = action.payload;
};

const handleUpdateBounds = (state, action) => {
  state.bounds = action.payload;
};

const handleUpdateDraw = (state, action) => {
  state.draw = action.payload;
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.END]: handleFetchEnd,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.UPDATE.FILTERS]: handleUpdateFilters,
  [ACTION_TYPES.UPDATE.TILE]: handleUpdateTile,
  [ACTION_TYPES.UPDATE.BOUNDS]: handleUpdateBounds,
  [ACTION_TYPES.UPDATE.DRAW]: handleUpdateDraw,
  [ACTION_TYPES.RESET.BOUNDS]: handleResetBounds,
});
