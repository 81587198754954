import reducer from './summary.reducer';
import * as constant from './summary.constant';
import * as action from './summary.action';
import * as selector from './summary.selector';
import saga from './summary.saga';
import initialState from './summary.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
