import flow from 'lodash/flow';
import { takeLatest, select, call, put, fork } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

import toaster from '@spot/shared-store/toaster';

import getApiOperationsTotalsService from '@spot/shared-services/analysis_esg/getApiOperationsTotals.service';

import filters from '../filters';

import currentState from '.';

import { ACTION_TYPES } from '../analytics.constant';

const handleServiceGet = function* () {
  const seletors = {
    filters: yield select(filters.selector.selectState),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      getApiOperationsTotalsService as any,
      omit(pickBy(seletors.filters.currentFilter), 'offset', 'limit')
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceGetFilter = function* () {
  const selectors = {
    filters: yield select(filters.selector.selectState),
  };

  const { currentFilter, lastFilter } = selectors.filters;

  const omitCurrentFilter = omit(currentFilter, ['offset']);
  const omitLastFilter = omit(lastFilter, ['offset']);

  if (isEqual(omitCurrentFilter, omitLastFilter)) {
    return false;
  }

  yield fork(handleServiceGet);
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    filters.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleServiceGetFilter
  );

  yield takeLatest(ACTION_TYPES.SERVICE.GET_ALL, handleServiceGet);
}

export default {
  watch,
};
