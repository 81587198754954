import React, { Fragment } from 'react';
import { Box, Divider, Typography, Tabs, Tab, Button } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';

import ROUTES from '@spot-spotesg/constants/routes.constant';
import statusConstant from '@spot-spotesg/constants/status.constant';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';

import BoxWhite from '@spot-spotesg/components/Box/BoxWhite.component';
import BoxAnalyticsComponent from '../components/BoxAnalytics.component';
import MapComplianceComponent from '../components/MapCompliance.component';
import TableComponent from '@spot-spotesg/components/Table.component';

import toDateHourUtil from '@spot-spotesg/utils/toDateHour.util';

import AppLayout from '@spot-spotesg/layouts/App.layout';

import analyse from '../store/analyse';
import mapDualLeft from '../store/mapDual/mapDualLeft';
import mapDualRight from '../store/mapDual/mapDualRight';
import print from '../store/print';

const AnalyticsIdScreen = () => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = React.useState(1);

  const history = useHistory();
  const { id: idRouter }: any = useParams();

  const dispatchRedux = {
    serviceGet: flow(analyse.action.serviceGet, dispatch),
    resetStateLeft: flow(mapDualLeft.action.resetState, dispatch),
    resetStateRight: flow(mapDualRight.action.resetState, dispatch),
    updatePrint: flow(print.action.updatePrint, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(analyse.selector.selectLoading),
    data: useSelector(analyse.selector.selectData),
  };

  useEffectOnce(() => {
    if (idRouter) {
      dispatchRedux.serviceGet(idRouter);
    }
  });

  const infos = (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={5}>
      {[
        {
          label: 'ID da análise',
          amount: selectorRedux?.data?.data?.id,
          gridColumn: 'span 4',
        },
        {
          label: 'Data da análise',
          amount: toDateHourUtil(selectorRedux?.data?.data?.finished_at),
        },
        {
          label: 'Enviado por',
          amount: selectorRedux?.data?.data?.user_name,
        },
        {
          label: 'Status',
          amount: statusConstant[selectorRedux?.data?.data?.status],
        },
        {
          label: 'Descrição',
          amount: selectorRedux?.data?.data?.description,
          gridColumn: 'span 4',
        },
        {
          label: 'Nome do arquivo',
          amount: selectorRedux?.data?.data?.file_name,
          gridColumn: 'span 1',
        },
        {
          label: 'Tipo de arquivo',
          amount: selectorRedux?.data?.data?.file_type,
        },
      ].map((v, index) => (
        <BoxAnalyticsComponent key={`box-operation-${index}`} {...v} />
      ))}
    </Box>
  );

  const up = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Button
            onClick={() => history.push(ROUTES.ANALYTICS.ROOT)}
            startIcon={<ArrowBackIcon />}
            sx={{ color: 'info.main' }}
          >
            Voltar
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box display="flex" gap={2}>
        <Typography
          variant="subtitle2"
          color="secondary.main"
          sx={{
            bgcolor: 'secondary.light3',
            px: '8px',
            py: '4px',
            borderRadius: 0.5,
          }}
        >
          criado em: {toDateHourUtil(selectorRedux?.data?.data?.created_at)}
        </Typography>
        <Typography
          variant="subtitle2"
          color="secondary.main"
          sx={{
            bgcolor: 'secondary.light3',
            px: '8px',
            py: '4px',
            borderRadius: 0.5,
          }}
        >
          atualizado em: {toDateHourUtil(selectorRedux?.data?.data?.updated_at)}
        </Typography>
      </Box>
    </Box>
  );

  const alerts = (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <ReportProblemTwoToneIcon color="primary" sx={{ fontSize: 22 }} />
        <Typography variant="subtitle1" fontWeight={500} color="secondary.main">
          Alertas ()
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        {selectorRedux?.data?.data?.areas?.map((a) => (
          <Typography
            variant="subtitle1"
            color="gray.black"
            fontWeight={500}
            key={a?.id}
            sx={{ bgcolor: 'warning.light3', p: 2, borderRadius: '6px' }}
          >
            {a?.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );

  const analytics = (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box height="298px">
        <MapComplianceComponent
          layers={selectorRedux.data?.data?.areas || []}
          geom={selectorRedux?.data?.compliance?.property?.geojson}
        />
      </Box>

      <TableComponent
        pagination={false}
        name="environmentalCompliance"
        data={selectorRedux.data?.data?.areas}
        columns={[
          {
            name: 'name',
            label: 'Camada',
          },
          {
            name: 'intersection_area_ha',
            label: 'Área de obreposição (ha)',
            align: 'center',
            render: ({ intersection_area_ha }) => (
              <Typography
                color="gray.400"
                variant="subtitle2"
                textAlign="center"
              >
                {Number(intersection_area_ha).toLocaleString('pt-br', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}
              </Typography>
            ),
          },
          {
            name: 'intersection_area_percentage',
            label: 'Área de Sobreposição (%)',
            align: 'center',
            render: ({ intersection_area_percentage }) => (
              <Typography
                color="gray.400"
                variant="subtitle2"
                textAlign="center"
              >
                {Number(intersection_area_percentage).toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            ),
          },
        ]}
      />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          color="secondary"
          variant="contained"
          sx={{ bgcolor: 'info.main' }}
          onClick={() => {
            dispatchRedux.updatePrint({
              layers: selectorRedux.data?.data?.areas,
              geom: selectorRedux?.data?.compliance?.property?.geojson,
            });

            history.push(
              ROUTES.ANALYTICS.ID_PRINT(selectorRedux?.data?.data?.id)
            );
          }}
        >
          Exportar PDF
        </Button>
      </Box>
    </Box>
  );

  return (
    <AppLayout title={false} breadcrumb={['Análises', 'Detalhes da análise']}>
      <Box display="flex" flexDirection="column" gap={2}>
        <BoxWhite loading={false}>
          <Box display="flex" flexDirection="column" gap={3}>
            {up}
            {infos}
          </Box>
        </BoxWhite>
        <Box display="flex" flexDirection="column" gap={3}>
          <BoxWhite loading={false}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Tabs
                centered
                value={currentTab}
                onChange={(_, val) => setCurrentTab(val)}
                sx={{
                  button: {
                    flex: 1,
                    maxWidth: 'none',
                  },
                }}
              >
                <Tab label="ANÁLISE" value={1} />
                <Tab
                  label="VALIDAÇÃO"
                  value={2}
                  onClick={() => {
                    dispatchRedux.resetStateLeft();
                    dispatchRedux.resetStateRight();

                    history.push(
                      ROUTES.ANALYTICS.ID_VALIDATION(
                        selectorRedux?.data?.data?.id
                      )
                    );
                  }}
                />
              </Tabs>

              {currentTab === 1 && analytics}
              {currentTab === 2 && <span>tt2</span>}
            </Box>
          </BoxWhite>
        </Box>
      </Box>
    </AppLayout>
  );
};

export default AnalyticsIdScreen;
