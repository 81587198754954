import React, { useRef } from 'react';
import {
  Box,
  Typography,
  Divider,
  TextField,
  Popover,
  IconButton,
  Button,
  Tooltip,
  CircularProgress,
  Card,
  Slider,
  GlobalStyles,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams, useHistory } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { Formik } from 'formik';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import unionBy from 'lodash/unionBy';
import orderBy from 'lodash/orderBy';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';
import toDateHourUtil from '@spot-spotesg/utils/toDateHour.util';

import ROUTES from '@spot-spotesg/constants/routes.constant';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import ArticleIcon from '@mui/icons-material/FileDownload';

import CloudIcon from '@mui/icons-material/Cloud';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import MapValidationComponent from '../components/MapValidation.component';
import MapDualValidationComponent from '../components/MapDualValidation.component';
import MiniCanvas from '@spot-spotesg/components/MiniCanvas';

import ThemeProvider from '@spot-spotesg/providers/Theme.provider';

import analyse from '../store/analyse';
import mapDualRight from '../store/mapDual/mapDualRight';
import mapDualLeft from '../store/mapDual/mapDualLeft';
import print from '../store/print';
import satellites from '../store/satellites';

const SelectDay = ({
  images,
  handleGetMap,
  currentImageId,
  setCurrentImageId,
  satellites,
}) => {
  const swiperRef = useRef({
    slidePrev: () => {},
    slideNext: () => {},
  });

  return (
    <Box display="flex" width="100%" height="100%">
      <Box display="flex">
        <Button
          color="primary"
          sx={{ bgcolor: '#111418' }}
          size="small"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ArrowBackIosIcon sx={{ color: 'white' }} />
        </Button>
      </Box>

      <Swiper
        spaceBetween={0}
        slidesPerView="auto"
        style={{ width: '100%' }}
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        initialSlide={images?.findIndex((f) => f.id === currentImageId) || 0}
        centeredSlides={true}
      >
        {images?.map((i) => (
          <SwiperSlide key={i?.id} style={{ maxWidth: 90 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              py={1}
              sx={{
                cursor: 'pointer',
                '&:hover': { bgcolor: 'gray.500' },
                '&': {
                  ...(currentImageId === i?.id ? { bgcolor: 'gray.500' } : {}),
                },
                mr: 1,
              }}
              onClick={(ev) => {
                setCurrentImageId({
                  image_id: i?.id,
                  date: i?.date,
                  satellite_obj: satellites?.find(
                    (st) => st.satellite === i?.satellite
                  ),
                });
                handleGetMap({ image_id: i?.id });
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                gap={1}
                alignItems="center"
              >
                {Number(i?.cloud_cover) < 20 ? (
                  <SatelliteAltIcon sx={{ color: 'white' }} />
                ) : (
                  <CloudIcon fontSize="small" sx={{ color: 'white' }} />
                )}
                <Typography
                  color="white"
                  fontWeight={500}
                  fontSize={11}
                  variant="caption"
                >
                  {Number(i?.cloud_cover).toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                  })}
                  %
                </Typography>
              </Box>
              <Typography
                color="white"
                fontWeight={500}
                fontSize={12}
                variant="subtitle1"
              >
                {format(new Date(i?.date.replace('-', '/')), 'dd MMM yyyy', {
                  locale: ptBR,
                })}
              </Typography>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      <Box display="flex">
        <Button
          color="primary"
          sx={{ bgcolor: '#111418' }}
          size="small"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ArrowForwardIosIcon sx={{ color: 'white' }} />
        </Button>
      </Box>
    </Box>
  );
};

const BoxWithSelect = ({
  title,
  infos,
  onCheck = (checked, geom) => {},
  geom,
  checked,
  indeterminate = false,
}) => {
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Box>
        <Checkbox
          indeterminate={indeterminate}
          onChange={({ target: { checked } }) => {
            onCheck(checked, geom);
          }}
          checked={checked}
          size="small"
          sx={{ color: 'white' }}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" color="white">
          {title}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption" color="gray.300">
            {infos}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const AnalyticsIdValidationScreen = () => {
  const dispatch = useDispatch();

  const selectorRedux = {
    loading: useSelector(analyse.selector.selectLoading),
    data: useSelector(analyse.selector.selectData),
    loadingMapRight: useSelector(mapDualRight.selector.selectLoading),
    loadingMapLeft: useSelector(mapDualLeft.selector.selectLoading),
    filtersRight: useSelector(mapDualRight.selector.selectFilters),
    filtersLeft: useSelector(mapDualLeft.selector.selectFilters),
    dataRight: useSelector(mapDualRight.selector.selectData),
    dataLeft: useSelector(mapDualLeft.selector.selectData),
    tileRight: useSelector(mapDualRight.selector.selectTile),
    tileLeft: useSelector(mapDualLeft.selector.selectTile),
    statePrint: useSelector(print.selector.selectState),
    satellites: useSelector(satellites.selector.selectData),
  };

  const dispatchRedux = {
    serviceGet: flow(analyse.action.serviceGet, dispatch),
    servicePostRight: flow(mapDualRight.action.servicePost, dispatch),
    servicePostLeft: flow(mapDualLeft.action.servicePost, dispatch),
    serviceGetMapIdRight: flow(mapDualRight.action.serviceGetMapId, dispatch),
    serviceGetMapIdLeft: flow(mapDualLeft.action.serviceGetMapId, dispatch),
    serviceGetSatellites: flow(satellites.action.serviceGet, dispatch),
    updatePrint: flow(print.action.updatePrint, dispatch),
  };

  const [conclusion, setConclusion] = React.useState('');
  const [modalConclusion, setModalConclusion] = React.useState(false);
  const [areaSelected, setAreaSelected] = React.useState<any>(
    selectorRedux?.statePrint?.areaSelected || []
  );
  const [showFilters, setShowFilters] = React.useState(true);
  const [geomSelected, setGeomSelected] = React.useState<any>(null);
  const [key, setKey] = React.useState(v4());
  const [mapHeight, setMapHeight] = React.useState<any>(null);
  const [bottomHeight, setBottomHeight] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [activeSyncMaps, setActiveSyncMaps] = React.useState(false);
  const [mapLeftImageId, setMapLeftImageId] = React.useState<any>({
    image_id: selectorRedux.statePrint?.left?.image_id,
    date: selectorRedux.statePrint?.left?.date,
    satellite_obj: selectorRedux.statePrint?.left?.satellite_obj,
  });
  const [mapRightImageId, setMapRightImageId] = React.useState<any>({
    image_id: selectorRedux.statePrint?.right?.image_id,
    date: selectorRedux.statePrint?.right?.date,
    satellite_obj: selectorRedux.statePrint?.right?.satellite_obj,
  });

  const history = useHistory();

  const { id: idRouter }: any = useParams();

  const mapDivRef: any = React.useRef(null);
  const bottomDivRef: any = React.useRef(null);
  const filterRef: any = React.useRef(null);

  useEffectOnce(() => {
    if (idRouter) {
      dispatchRedux.serviceGet(idRouter);
      dispatchRedux.serviceGetSatellites();
    }
  });

  React.useEffect(() => {
    if (selectorRedux.tileRight !== null || selectorRedux.tileLeft !== null) {
      setKey(v4());
    }
  }, [selectorRedux.tileRight, selectorRedux.tileLeft]);

  React.useEffect(() => {
    if (selectorRedux.loadingMapLeft === false) {
      if (
        selectorRedux.tileLeft === undefined &&
        selectorRedux.dataLeft?.least_cloud?.id
      ) {
        setMapLeftImageId({
          image_id: selectorRedux.dataLeft?.least_cloud?.id,
          date: selectorRedux.dataLeft?.least_cloud?.date,
          satellite_obj: selectorRedux?.satellites?.find(
            (st) =>
              st.satellite === selectorRedux.dataLeft?.least_cloud?.satellite
          ),
        });

        dispatchRedux.serviceGetMapIdLeft({
          image_id: selectorRedux.dataLeft?.least_cloud?.id,
        });
      }
    }
  }, [selectorRedux.loadingMapLeft]);

  React.useEffect(() => {
    if (selectorRedux.loadingMapRight === false) {
      if (
        selectorRedux.tileRight === undefined &&
        selectorRedux.dataRight?.least_cloud?.id
      ) {
        setMapRightImageId({
          image_id: selectorRedux.dataRight?.least_cloud?.id,
          date: selectorRedux.dataRight?.least_cloud?.date,
          satellite_obj: selectorRedux?.satellites?.find(
            (st) =>
              st.satellite === selectorRedux.dataRight?.least_cloud?.satellite
          ),
        });

        dispatchRedux.serviceGetMapIdRight({
          image_id: selectorRedux.dataRight?.least_cloud?.id,
        });
      }
    }
  }, [selectorRedux.loadingMapRight]);

  // React.useEffect(() => {
  //   setKey(v4());
  //   console.log('herererer');
  // }, [areaSelected]);

  React.useEffect(() => {
    if (selectorRedux.loading === false) {
      setGeomSelected(selectorRedux.data.data);

      setKey(v4());
    }
  }, [selectorRedux.loading]);

  React.useEffect(() => {
    setMapHeight(mapDivRef?.current?.clientHeight);
    setBottomHeight(bottomDivRef?.current?.clientHeight);
  }, []);

  const filters = (position) => {
    const value =
      position === 'right'
        ? selectorRedux.filtersRight
        : selectorRedux.filtersLeft;

    const loading =
      position === 'right'
        ? selectorRedux.loadingMapRight
        : selectorRedux.loadingMapLeft;

    return (
      <Formik
        initialValues={{
          satellite: value?.satellite || 'COPERNICUS/S2_SR_HARMONIZED',
          date_begin: value?.date_begin || new Date('2022-01-02'),
          date_end: value?.date_end || new Date(),
        }}
        enableReinitialize
        onSubmit={async (values) => {
          if (position === 'right') {
            dispatchRedux.servicePostRight(values);

            return true;
          }

          if (position === 'left') {
            dispatchRedux.servicePostLeft(values);

            return true;
          }

          return true;
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Box display="flex" gap={2} flexDirection="column">
            <Box display="flex" gap={2}>
              <TextField
                select
                size="small"
                SelectProps={{ native: true }}
                value={values?.satellite}
                onChange={({ target: { value } }) => {
                  setFieldValue('satellite', value);
                }}
                sx={{
                  bgcolor: '#111418',
                  color: 'white',
                  'label, input, select': { color: '#b7b7b7 !important' },
                  svg: {
                    color: 'white',
                  },
                }}
              >
                <option value="">Selecione o satélite</option>
                {selectorRedux?.satellites?.map((sat) => (
                  <option key={sat?.satellite} value={sat?.satellite}>
                    {sat?.description}
                  </option>
                ))}
              </TextField>
              <Box display="flex" gap={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <>
                    <DatePicker
                      label="Início"
                      inputFormat="dd/MM/yyyy"
                      value={values?.date_begin}
                      openTo={'year'}
                      minDate={
                        new Date(
                          selectorRedux?.satellites?.find(
                            (f) => f.satellite === values?.satellite
                          )?.begin_date
                        )
                      }
                      maxDate={
                        new Date(
                          selectorRedux?.satellites?.find(
                            (f) => f.satellite === values?.satellite
                          )?.end_date
                        )
                      }
                      onChange={(newValue) => {
                        setFieldValue('date_begin', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          variant="filled"
                          sx={{
                            bgcolor: '#111418',
                            'label, input': { color: '#b7b7b7' },
                            svg: {
                              color: 'white',
                            },
                          }}
                        />
                      )}
                    />
                    <DatePicker
                      label="Fim"
                      inputFormat="dd/MM/yyyy"
                      value={values?.date_end}
                      openTo={'year'}
                      minDate={
                        new Date(
                          selectorRedux?.satellites?.find(
                            (f) => f.satellite === values?.satellite
                          )?.begin_date
                        )
                      }
                      maxDate={
                        new Date(
                          selectorRedux?.satellites?.find(
                            (f) => f.satellite === values?.satellite
                          )?.end_date
                        )
                      }
                      onChange={(newValue) => {
                        setFieldValue('date_end', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          variant="filled"
                          sx={{
                            bgcolor: '#111418',
                            'label, input': { color: '#b7b7b7' },
                            svg: {
                              color: 'white',
                            },
                          }}
                        />
                      )}
                    />
                  </>
                </LocalizationProvider>
              </Box>
              <Box display="flex" gap={2} flexDirection="row">
                <Box display="flex" gap={2}>
                  <LoadingButton
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={submitForm}
                    loading={loading}
                    sx={{ bgcolor: '#111418', svg: { color: 'white' } }}
                  >
                    Pesquisar
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    );
  };

  const polygons = (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      position="relative"
      height="100%"
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            history.push(ROUTES.ANALYTICS.ID(idRouter));
          }}
        >
          <Box
            component={ArrowBackIcon}
            sx={{ color: 'white', fontSize: 14, mb: '1px' }}
          />
          <Typography
            color="white"
            variant="caption"
            sx={{
              borderBottom: '1px solid transparent',
              '&:hover': {
                borderBottomColor: 'white',
              },
            }}
          >
            Voltar
          </Typography>
        </Box>
        <Divider sx={{ bgcolor: 'gray.500' }} />
        <Typography color="white" variant="subtitle1">
          Análise
        </Typography>
        <Divider sx={{ bgcolor: 'gray.500' }} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex">
            <Typography variant="subtitle2" color="white">
              Descrição: {selectorRedux.data?.data?.description || ''}
            </Typography>
          </Box>
          <Typography variant="subtitle2" color="white">
            Nome do arquivo: {selectorRedux.data?.data?.file_name || ''}
          </Typography>
          <Box display="flex">
            <Typography variant="subtitle2" color="white">
              Àrea total:{' '}
              {Number(selectorRedux.data?.compliance.property.size_ha).toFixed(
                2
              )}
              ha
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle2" color="white">
              Criado em: {toDateHourUtil(selectorRedux.data?.created_at)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="subtitle2" color="white">
              Observação:
            </Typography>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setModalConclusion(true)}
            >
              {conclusion ? 'Editar observação' : 'Adicionar observação'}
            </Button>
          </Box>
        </Box>
        <Divider sx={{ bgcolor: 'gray.500' }} />
        <Typography color="white" variant="subtitle1">
          Sobreposições
        </Typography>
        <Divider sx={{ bgcolor: 'gray.500' }} />
        <Box display="flex" flexDirection="column" gap={2}>
          {selectorRedux.data?.data?.areas?.map((g, index) => (
            <Box display="flex" gap={1} key={`box-polygons-${index}`}>
              <Box display="flex" flexDirection="column" ml={-1.5}>
                <BoxWithSelect
                  indeterminate={
                    g?.results
                      ?.map((gr) => areaSelected.some((as) => as.id === gr.id))
                      .filter((a) => !!a).length > 0 &&
                    g?.results
                      ?.map((gr) => areaSelected.some((as) => as.id === gr.id))
                      .some((e) => e === false)
                  }
                  checked={g?.results
                    ?.map((gr) => areaSelected.some((as) => as.id === gr.id))
                    .some((e) => e === true)}
                  title={
                    <Typography variant="overline" color="white">
                      {g?.name}
                    </Typography>
                  }
                  infos={`${g?.intersection_area_ha}ha - ${Number(
                    g?.intersection_area_percentage || 0
                  ).toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                  })}%`}
                  geom={g?.results}
                  onCheck={(checked, geom) => {
                    let selectedAreas: any = [];

                    if (checked) {
                      selectedAreas = unionBy(areaSelected, geom, 'id');
                    } else {
                      selectedAreas = areaSelected.filter(
                        (as) => !geom.some((fg) => fg.id === as.id)
                      );
                    }

                    setAreaSelected(selectedAreas);
                  }}
                />

                <Box
                  display="flex"
                  flexDirection="column"
                  pl={1}
                  gap={2}
                  mt={1}
                >
                  {orderBy(
                    g?.results || [],
                    (gm) => gm?.geojson?.properties?.year,
                    'desc'
                  ).map((gm) => (
                    <BoxWithSelect
                      key={gm.id}
                      checked={!!areaSelected.find((as) => as.id === gm.id)}
                      title={`${gm?.geojson?.properties?.name} - ${gm?.geojson?.properties?.year}`}
                      infos={
                        <Box mt={0} style={{ maxWidth: 250 }}>
                          <Tooltip
                            placement="top"
                            title={
                              <Box>
                                <Box>
                                  {
                                    gm?.geojson_difference?.properties
                                      ?.description
                                  }
                                </Box>
                              </Box>
                            }
                          >
                            <Box>
                              <Typography variant="caption" color="white">
                                Data:{' '}
                                {toDateHourUtil(
                                  gm?.geojson_difference?.properties?.created_at
                                )}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      }
                      geom={gm}
                      onCheck={(checked, geom) => {
                        const formattedGeom = [geom];

                        let selectedAreas: any = [];

                        if (checked) {
                          selectedAreas = unionBy(
                            areaSelected,
                            formattedGeom,
                            'id'
                          );
                        } else {
                          selectedAreas = areaSelected.filter(
                            (as) => !formattedGeom.some((fg) => fg.id === as.id)
                          );
                        }

                        setAreaSelected(selectedAreas);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );

  const cockpitDualMap = (
    <Box display="flex" flexDirection="column" position="relative">
      <Box
        flex={1}
        height={mapHeight}
        key={`map-${mapHeight}`}
        ref={mapDivRef}
        position="relative"
      >
        {geomSelected && (
          <MapDualValidationComponent
            geom={geomSelected.geojson}
            areaSelected={areaSelected}
            activeSync={activeSyncMaps}
          />
        )}

        <Box
          position="absolute"
          bottom={showFilters ? filterRef?.current?.clientHeight + 16 : 16 + 62}
          right={16}
          zIndex={999}
        >
          <Tooltip placement="top" title="Sincronizar zoom dos mapas">
            <Button
              variant="contained"
              color={'primary'}
              onClick={() => {
                setActiveSyncMaps(!activeSyncMaps);
              }}
              sx={{
                bgcolor: !activeSyncMaps ? '#111418' : 'secondary',
                color: 'white',
                mr: 1,
              }}
            >
              {activeSyncMaps ? <JoinFullIcon /> : <JoinInnerIcon />}
            </Button>
          </Tooltip>

          <Tooltip placement="top" title="Exportar dados da análise">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatchRedux.updatePrint({
                  areaSelected,
                  conclusion,
                  geom: geomSelected.geojson,
                  left: { ...selectorRedux.filtersLeft, ...mapLeftImageId },
                  right: { ...selectorRedux.filtersRight, ...mapRightImageId },
                });

                history.push(ROUTES.ANALYTICS.ID_VALIDATION_PRINT(idRouter));
              }}
              sx={{ bgcolor: '#111418', color: 'white', mr: 1 }}
            >
              <ArticleIcon />
            </Button>
          </Tooltip>

          {showFilters && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowFilters(false);

                window.scrollTo(0, 0);
              }}
              sx={{ bgcolor: '#111418' }}
            >
              <KeyboardArrowDownIcon sx={{ color: 'white' }} />
            </Button>
          )}

          {!showFilters && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowFilters(true);

                setTimeout(() => {
                  window.scrollTo(0, document.body.scrollHeight);
                });
              }}
              sx={{ bgcolor: '#111418' }}
            >
              <KeyboardArrowUpIcon sx={{ color: 'white' }} />
            </Button>
          )}
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="50% 50%"
        sx={{ width: '100%' }}
        position="absolute"
        bottom={0}
        left={0}
        ref={filterRef}
      >
        <Box display="flex" flexDirection="column">
          <Box
            bgcolor="#111418"
            width="100%"
            position={'relative'}
            zIndex={99999}
            bottom={showFilters ? 0 : -86}
            height="62px"
          >
            <SelectDay
              satellites={selectorRedux.satellites}
              images={selectorRedux.dataLeft?.imagesFormatted}
              handleGetMap={dispatchRedux.serviceGetMapIdLeft}
              currentImageId={mapLeftImageId?.image_id}
              setCurrentImageId={setMapLeftImageId}
            />
          </Box>
          <Box flex={1} display="flex" zIndex={showFilters ? 9999 : -1}>
            <Box bgcolor="#212930" p={2} flex={1}>
              {filters('left')}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            bgcolor="#111418"
            width="100%"
            zIndex={99999}
            position="relative"
            bottom={showFilters ? 0 : -86}
            height="62px"
          >
            <SelectDay
              satellites={selectorRedux.satellites}
              images={selectorRedux.dataRight?.imagesFormatted}
              handleGetMap={dispatchRedux.serviceGetMapIdRight}
              currentImageId={mapRightImageId?.image_id}
              setCurrentImageId={setMapRightImageId}
            />
          </Box>
          <Box flex={1} display="flex" zIndex={showFilters ? 9999 : -1}>
            <Box bgcolor="#212930" p={2} flex={1}>
              {filters('right')}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const sidebar = (
    <Box
      p={3}
      display="flex"
      flexDirection="column"
      bgcolor="#212930"
      sx={{ overflowY: 'auto' }}
    >
      {polygons}
    </Box>
  );

  return (
    <ThemeProvider>
      <GlobalStyles
        styles={{
          '.MuiPickersPopper-root': {
            zIndex: '99999 !important',
          },
        }}
      />
      {!selectorRedux.loading && (
        <Box
          display="grid"
          gridTemplateColumns="1fr 360px"
          height="100vh"
          key={key}
        >
          {cockpitDualMap}
          {sidebar}
        </Box>
      )}

      {selectorRedux.loading && (
        <Box
          bgcolor="#212930"
          display="flex"
          flexDirection="column"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      <Dialog open={modalConclusion} onClose={() => setModalConclusion(false)}>
        <DialogTitle>Observação</DialogTitle>
        <DialogContent>
          <Box minWidth={500}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Observação"
              type="email"
              fullWidth
              multiline
              value={conclusion}
              variant="standard"
              onChange={({ target: { value } }) => setConclusion(value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalConclusion(false)}>Cancelar</Button>
          <Button onClick={() => setModalConclusion(false)}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AnalyticsIdValidationScreen;
