import reducer from './mapDualLeft.reducer';
import * as constant from './mapDualLeft.constant';
import * as action from './mapDualLeft.action';
import * as selector from './mapDualLeft.selector';
import saga from './mapDualLeft.saga';
import initialState from './mapDualLeft.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
