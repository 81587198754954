import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  file: any;
  type: 'KML' | 'KMZ' | 'GEOJSON';
  description?: string;
};

export default async function postApiAnalysisService(payload: Payload) {
  const url = ['analysis'].join('/');

  const formData = new FormData();

  formData.append('file', payload.file);
  formData.append('type', payload.type);
  formData.append('description', payload?.description || '-');

  return fetch(url, {
    method: 'POST',
    ms: 'ANALYSIS_ESG',
    auth: true,
    defaultHeaders: false,
    body: formData,
  });
}
