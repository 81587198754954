import { useSelector, useDispatch } from 'react-redux';
import { ptBR } from '@mui/material/locale';
import {
  createTheme,
  ThemeProvider as ThemeProviderMUI,
  CssBaseline,
} from '@mui/material';

import themeConstants from '@spot/shared-constants/theme.constant';

import misc from '@spot-spotesg/store/configs/misc';

const ThemeProvider = ({ children }) => {
  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };

  const { mode } = selectorRedux;

  const theme = createTheme(themeConstants(mode) as any, ptBR);

  return (
    <ThemeProviderMUI theme={theme}>
      <CssBaseline />

      {children}
    </ThemeProviderMUI>
  );
};

export default ThemeProvider;
