import React from 'react';
import flow from 'lodash/flow';
import { Box, Typography, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import misc from '@spot-spotesg/store/configs/misc';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import AppLayout from '@spot-spotesg/layouts/App.layout';

import ROUTES from '@spot-spotesg/constants/routes.constant';
import statusConstant from '@spot-spotesg/constants/status.constant';

import BoxWhite from '@spot-spotesg/components/Box/BoxWhite.component';
import BoxSummary from '@spot-spotesg/components/Box/BoxSummary.component';
import TableComponent from '@spot-spotesg/components/Table.component';
import ChartPieComponent from '@spot-spotesg/components/ChartPie.component';
import ChartPieReferenceComponent from '@spot-spotesg/components/ChartPieReference.component';
import ChartBarComponent from '@spot-spotesg/components/ChartBar.component';
import Badge from '@mui/material/Badge';

import toNumber from '@spot-spotesg/utils/toNumber.util';
import toDateUtil from '@spot-spotesg/utils/toDate.util';

import summary from '../store/summary';

import totals from '../store/totals';
import details from '../store/details';
import sync from '../store/details';
import dashboard from '../store';

import Canvas from '../../../components/MiniCanvas';

const DashboardScreen = () => {
  const [key, setKey] = React.useState(v4());

  const history = useHistory();

  const dispatch = useDispatch();

  const dispatchRedux = {
    serviceGetAll: flow(dashboard.action.serviceGetAll, dispatch),
  };

  const selectorRedux = {
    dataSummary: useSelector(summary.selector.selectData),
    dataTotals: useSelector(totals.selector.selectData),
    dataSync: useSelector(sync.selector.selectData),
    dataDetails: useSelector(details.selector.selectDataResults),
    dataDetailsMetadata: useSelector(details.selector.selectDataMetadata),
    loadingDetails: useSelector(details.selector.selectLoading),
    loadingSync: useSelector(sync.selector.selectLoading),
    loadingTotals: useSelector(totals.selector.selectLoading),
    loadingSummary: useSelector(summary.selector.selectLoading),
    mode: useSelector(misc.selector.selectMode),
  };

  const columns = [
    {
      label: '',
      name: 'polygon',
      render: ({ geojson }) => (
        <Canvas polygon={geojson} width={129} height={56} />
      ),
    },
    {
      label: 'ID',
      name: 'id',
    },
    {
      label: 'Descrição',
      name: 'description',
    },
    {
      label: 'Data Análise',
      name: 'finished_at',
      render: ({ finished_at }) => (
        <Typography color="gray.400" variant="subtitle2">
          {finished_at && toDateUtil(finished_at)}
        </Typography>
      ),
    },
    {
      label: 'Divergências',
      name: 'count_divergents',
      render: ({ count_divergents }) => (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          sx={{
            '.MuiBadge-badge': {
              align: 'center',
              color: 'gray',
              fontSize: '14px',
              fontWeight: 700,
              bgcolor:
                count_divergents > 1
                  ? '#F1A57D'
                  : count_divergents == 1
                  ? '#FFE7B8'
                  : '#D6D8DC',
            },
          }}
        >
          <Badge badgeContent={count_divergents}></Badge>
        </Box>
      ),
    },
    {
      label: 'Status',
      name: 'status',
      render: ({ status }) => (
        <Typography color="gray.400" variant="subtitle2">
          {statusConstant[status] || ''}
        </Typography>
      ),
    },
    {
      name: 'action',
      render: ({ id }) => (
        <Button
          onClick={() => {
            history.push(ROUTES.ANALYTICS.ID(id));
          }}
          sx={{ color: 'info.main' }}
        >
          Ver mais
        </Button>
      ),
    },
  ];

  const summaryElm = (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
      <BoxSummary
        label="Total de análises"
        amount={toNumber(selectorRedux?.dataTotals?.total_analysis)}
        img="/img/analysis.png"
        loading={selectorRedux.loadingTotals}
      />
      <BoxSummary
        label="Análises sem divergências"
        amount={toNumber(selectorRedux?.dataTotals?.total_analysis_success)}
        img="/img/successes.png"
        loading={selectorRedux.loadingTotals}
      />
      <BoxSummary
        label="Análises com divergências"
        amount={toNumber(selectorRedux?.dataTotals?.total_analysis_divergent)}
        img="/img/divergents.png"
        loading={selectorRedux.loadingTotals}
      />
    </Box>
  );
  const alerts = (
    <BoxWhite loading={selectorRedux.loadingTotals}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography color="secondary.main" fontWeight={600}>
            Divergências
          </Typography>
        </Box>
        <Box display="flex" gap={4} alignItems="center">
          <Box width={200} height={160}>
            <ChartPieComponent data={selectorRedux.dataSummary?.analysis} />
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <ChartPieReferenceComponent
              data={selectorRedux.dataSummary?.analysis}
            />
          </Box>
        </Box>
      </Box>
    </BoxWhite>
  );

  const total = (
    <BoxWhite loading={selectorRedux.loadingSummary}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography color="secondary.main" fontWeight={600}>
          Total de análises x Divergências - Por Mês
        </Typography>

        <Box height="300px">
          <ChartBarComponent data={selectorRedux.dataSummary || []} />
        </Box>
      </Box>
    </BoxWhite>
  );

  const table = (
    <BoxWhite>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography color="secondary.main" fontWeight={500} variant="subtitle1">
          Últimas 10 Análises
        </Typography>

        <TableComponent
          columns={columns}
          name="details"
          loading={selectorRedux.loadingDetails}
          data={selectorRedux.dataDetails}
          metadata={selectorRedux.dataDetailsMetadata?.resultset}
          pagination={false}
        />
      </Box>
    </BoxWhite>
  );

  return (
    <AppLayout title="Dashboard">
      <Box display="flex" flexDirection="column" gap={3}>
        {summaryElm}
        <Box display="grid" gridTemplateColumns="30% 1fr" gap={3}>
          {alerts}
          {total}
        </Box>
        {table}
      </Box>
    </AppLayout>
  );
};

export default React.memo(DashboardScreen);
