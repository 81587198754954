import React, { PureComponent } from 'react';

import { useTheme } from '@mui/styles';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';

import misc from '@spot-spotesg/store/configs/misc';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function ChartPieComponent({ data: dataProp }) {
  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };

  const { mode } = selectorRedux;
  if (!dataProp) {
    dataProp = [];
  }

  const theme: any = useTheme();

  const COLORS = [
    '#64dd17',
    '#ffd54f',
    '#00b8d4',
    '#f44336',
    '#7c4dff',
    '#009688',
    '#75a7ff',
    '#ff8a65',
    '#4db6ac',
    '#90a4ae',
  ];

  const dataParsed = dataProp.map((a) => {
    return { name: a.name, value: Number(a.count) };
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie
          data={orderBy(dataParsed, 'value', 'desc')}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {orderBy(dataParsed, 'value', 'desc').map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
