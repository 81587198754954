import { flow } from 'lodash';
import { NAME } from './mapDualRight.constant';

import { selectState as selectStatePrev } from '../mapDual.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectDataKey = (state) => state.data;
const selectDataResultsKey = (state: any) => state?.results;
const selectDataMetadataKey = (state: any) => state?.metadata;
const selectFiltersKey = (state) => state.filters;
const selectTileKey = (state) => state.tile;
const selectBoundsKey = (state) => state.bounds;
const selectDrawKey = (state) => state.draw;

export const selectState = flow(selectStatePrev, selectStateKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectData = flow(selectState, selectDataKey);
export const selectDataResults = flow(
  selectState,
  selectDataKey,
  selectDataResultsKey
);
export const selectDataMetadata = flow(
  selectState,
  selectDataKey,
  selectDataMetadataKey
);
export const selectDraw = flow(selectState, selectDrawKey);
export const selectBounds = flow(selectState, selectBoundsKey);
export const selectTile = flow(selectState, selectTileKey);
export const selectFilters = flow(selectState, selectFiltersKey);
