import { ACTION_TYPES } from './print.constant';

export const updatePrint = (payload) => ({
  type: ACTION_TYPES.UPDATE.PRINT,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
