import { combineReducers } from 'redux';

import user from './currentUser';
import users from './users';

import config from './config';

const reducer = combineReducers({
  [config.name]: config.reducer,
  [users.name]: users.reducer,
  [user.name]: user.reducer,
});

export default reducer;
