export const NAME = 'mapDualLeft';

const CONTEXT = 'ANALYSE::MAP_DUAL::MAP_DUAL_LEFT';

const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;

const SERVICE_GET_MAP_ID = `${CONTEXT}::SERVICE::GET::MAP_ID`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_BOUNDS = `${CONTEXT}::RESET::BOUNDS`;

const UPDATE_FILTERS = `${CONTEXT}::UPDATE::FILTERS`;
const UPDATE_TILE = `${CONTEXT}::UPDATE::TILE`;
const UPDATE_BOUNDS = `${CONTEXT}::UPDATE::BOUNDS`;
const UPDATE_DRAW = `${CONTEXT}::UPDATE::DRAW`;

const UPDATE = {
  FILTERS: UPDATE_FILTERS,
  TILE: UPDATE_TILE,
  BOUNDS: UPDATE_BOUNDS,
  DRAW: UPDATE_DRAW,
};

const SERVICE = {
  POST: SERVICE_POST,
  GET_MAP_ID: SERVICE_GET_MAP_ID,
};

const RESET = {
  STATE: RESET_STATE,
  BOUNDS: RESET_BOUNDS,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  SERVICE,
  UPDATE,
};
