import { parse, isValid, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

const toDateHourUtil = (date = new Date()) => {
  if (date != null) {
    const date2 = parse(date.toString(), 'yyyy-MM-dd', new Date(), {
      locale: ptBR,
    });

    if (isValid(date2))
      return format(new Date(date2), 'dd/MM/yyyy HH:mm', { locale: ptBR });
    else return format(new Date(date), 'dd/MM/yyyy HH:mm', { locale: ptBR });
  } else return '';
};

export default toDateHourUtil;
