import reducer from './config.reducer';
import * as constant from './config.constant';
import * as action from './config.action';
import * as selector from './config.selector';
import initialState from './config.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  initialState,
};
