const monthConstant = [
  { nome: 'january', sigla: 'Jan' },
  { nome: 'february', sigla: 'Fev' },
  { nome: 'march', sigla: 'Mar' },
  { nome: 'april', sigla: 'Abr' },
  { nome: 'may', sigla: 'Mai' },
  { nome: 'june', sigla: 'Jun' },
  { nome: 'july', sigla: 'Jul' },
  { nome: 'august', sigla: 'Ago' },
  { nome: 'september', sigla: 'Set' },
  { nome: 'october', sigla: 'Out' },
  { nome: 'november', sigla: 'Nov' },
  { nome: 'december', sigla: 'Dez' },
];

export default monthConstant;
