import reducer from './analyse.reducer';
import * as constant from './analyse.constant';
import * as action from './analyse.action';
import * as selector from './analyse.selector';
import saga from './analyse.saga';
import initialState from './analyse.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
