import { flow } from 'lodash';
import { NAME } from './config.constant';

import { selectState as selectStateProfile } from '../profile.selector';

const selectStateKey = (state) => state[NAME];
const selectTabKey = (state) => state.tab;

export const selectState = flow(selectStateProfile, selectStateKey);
export const selectTab = flow(selectState, selectTabKey);
