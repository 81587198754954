const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  filters: {},
  tile: null,
  bounds: null,
  draw: null,
};

export default initialState;
