import { format, lastDayOfMonth } from 'date-fns';

const today = new Date();
const lastDate = new Date(today.setMonth(today.getMonth() - 13));

const filter = {
  offset: '0',
  limit: '20',
  id: '',
  description: '',
  start_created_at: '',
  end_created_at: '',
  status: '',
};

const initialState = {
  currentFilter: filter,
  lastFilter: filter,
};

export default initialState;
