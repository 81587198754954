import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  offset?: string;
  limit?: string;
  id?: string;
  description?: string;
  start_created_at: string;
  end_created_at: string;
  status?: string;
};

export default async function getApiAnalysisService(filters: Filters) {
  const today = new Date();

  const newFilters = {
    ...filters,
    start_created_at:
      filters?.start_created_at ||
      new Date(today.setMonth(today.getMonth() - 13)),
    end_created_at: filters?.end_created_at || new Date(),
  };

  const filtersStringfy = qs.stringify(newFilters);

  const url = ['analysis', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'ANALYSIS_ESG',
    auth: true,
  });
}
