import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const BoxWhiteComponent = ({ children, loading = false, ...restProps }) => {
  return (
    <Box
      borderRadius={1}
      p={3}
      bgcolor="background.default"
      boxShadow="0px 0px 10px #0000000f"
      position="relative"
      {...restProps}
    >
      {children}

      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          bgcolor={'background.default'}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={9}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default BoxWhiteComponent;
