import flow from 'lodash/flow';
import pickBy from 'lodash/pickBy';
import { takeLatest, select, call, put, delay } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';

import getApiUsersService from '@spot/shared-services/user/getApiUsers.service';
import getApiUsersIdService from '@spot/shared-services/user/getApiUsersId.service';
import deleteApiUsersIdService from '@spot/shared-services/user/deleteApiUsersId.service';

import patchApiAccountsIdService from '@spot/shared-services/account/patchApiAccountsId.service';

import currentState from '.';

const handleServiceGet = function* (action) {
  const seletors = {
    filters: yield select(currentState.selector.selectFilters),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(() =>
      getApiUsersService(seletors.filters)
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePatch = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      patchApiAccountsIdService as any,
      payload?.user_id,
      pickBy({
        name: payload?.name,
        email: payload?.email,
        password: payload?.password,
        password_confirmation: payload?.password_confirmation,
      })
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Usuário atualizado com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceDelete = function* (action) {
  const selectors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload = {} } = action || {};

  const { user_id } = payload;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(deleteApiUsersIdService, user_id);

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Usuário removido com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(selectors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceGetId = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      getApiUsersIdService,
      payload?.user_id
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_ID,
    handleServiceGetId
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.DELETE,
    handleServiceDelete
  );
}

export default {
  watch,
};
