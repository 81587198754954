import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  satellite: string;
  date_begin: string;
  date_end: string;
  geometry: any;
};

const today = new Date();

export default async function postApiMapsService(filters: Filters) {
  const newFilters = {
    ...filters,
    date_begin:
      filters?.date_begin || new Date(today.setMonth(today.getMonth() - 1)),
    date_end: filters?.date_end || new Date(),
  };

  return fetch('maps', {
    method: 'POST',
    ms: 'TEMPORAL',
    body: JSON.stringify(newFilters),
    auth: true,
  });
}
