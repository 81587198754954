import reducer from './analysis.reducer';
import * as constant from './analysis.constant';
import * as action from './analysis.action';
import * as selector from './analysis.selector';
import saga from './analysis.saga';
import initialState from './analysis.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
