import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Formik, Form } from 'formik';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { useHistory } from 'react-router-dom';

import ROUTES from '@spot-spotesg/constants/routes.constant';
import statusConstant from '@spot-spotesg/constants/status.constant';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import toDateUtil from '@spot-spotesg/utils/toDate.util';

import BoxFilter from '@spot-spotesg/components/Box/BoxFilter.component';
import BoxWhite from '@spot-spotesg/components/Box/BoxWhite.component';
import TableComponent from '@spot-spotesg/components/Table.component';

import AppLayout from '@spot-spotesg/layouts/App.layout';

import dialogSendAnalytics from '../store/dialogs/dialogSendAnalytics';

import filtersState from '../store/filters';
import analysis from '../store/analysis';
import analytics from '../store';
import Canvas from '../../../components/MiniCanvas';
import Badge from '@mui/material/Badge';

const AnalyticsScreen = () => {
  const [key, setKey] = React.useState(v4());

  const history = useHistory();

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateFilters: flow(filtersState.action.updateFilters, dispatch),
    open: flow(dialogSendAnalytics.action.open, dispatch),
    serviceGetAll: flow(analytics.action.serviceGetAll, dispatch),
    resetState: flow(filtersState.action.resetState, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogSendAnalytics.selector.selectVisible),
    data: useSelector(analysis.selector.selectData),
    loading: useSelector(analysis.selector.selectLoading),
  };

  const filtersArr = (values) => {
    return [
      [
        {
          label: 'ID da análise',
          name: 'id',
        },
        {
          label: 'Descrição',
          name: 'description',
        },
        {
          label: 'Início',
          name: 'start_created_at',
          variant: 'date',
          textFieldProps: {
            InputProps: {
              endAdornment: <CalendarMonthIcon sx={{ color: 'gray.300' }} />,
              readOnly: true,
            },
          },
        },
        {
          label: 'Fim',
          name: 'end_created_at',
          variant: 'date',
          textFieldProps: {
            InputProps: {
              endAdornment: <CalendarMonthIcon sx={{ color: 'gray.300' }} />,
              readOnly: true,
            },
          },
        },
        {
          label: 'Status',
          name: 'status',
          options: [
            {},
            ...Object.keys(statusConstant).map((k) => ({
              label: statusConstant[k as any],
              value: k,
            })),
          ],
          textFieldProps: {
            select: true,
            SelectProps: {
              native: true,
            },
          },
        },
      ],
    ];
  };

  useEffectOnce(() => {
    dispatchRedux.serviceGetAll();
  });

  const filters = (
    <Formik
      key={key}
      enableReinitialize
      initialValues={{
        id: '',
        description: '',
        status: '',
        start_created_at: '',
        end_created_at: '',
      }}
      onSubmit={async (values) => {
        dispatchRedux.updateFilters(values);

        return true;
      }}
    >
      {({ values }) => (
        <Form>
          <BoxFilter
            formik
            fields={filtersArr(values)}
            key={`filters`}
            onEmpty={() => {
              dispatchRedux.resetState();
              dispatchRedux.serviceGetAll();

              setKey(v4());
            }}
          />
        </Form>
      )}
    </Formik>
  );

  const columns = [
    {
      label: '',
      name: 'polygon',
      render: ({ geojson }) => (
        <Canvas polygon={geojson} width={129} height={56} />
      ),
    },
    {
      label: 'ID',
      name: 'id',
    },
    {
      label: 'Descrição',
      name: 'description',
    },
    {
      label: 'Data da análise',
      name: 'finished_at',
      render: ({ finished_at }) => (
        <Typography color="gray.400" variant="subtitle2">
          {finished_at ? toDateUtil(finished_at) : ''}
        </Typography>
      ),
    },
    {
      label: 'Divergências',
      name: 'count_divergents',
      render: ({ count_divergents }) => (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          sx={{
            '.MuiBadge-badge': {
              align: 'center',
              color: 'gray',
              fontSize: '14px',
              fontWeight: 700,
              bgcolor:
                count_divergents > 1
                  ? '#F1A57D'
                  : count_divergents == 1
                  ? '#FFE7B8'
                  : '#D6D8DC',
            },
          }}
        >
          <Badge badgeContent={count_divergents}></Badge>
        </Box>
      ),
    },
    {
      label: 'Status',
      name: 'status',
      render: ({ status }) => (
        <Typography color="gray.400" variant="subtitle2">
          {statusConstant[status] || ''}
        </Typography>
      ),
    },
    {
      name: 'action',
      render: ({ id }) => (
        <Button
          onClick={() => {
            history.push(ROUTES.ANALYTICS.ID(id));
          }}
          sx={{ color: 'info.main' }}
        >
          Ver mais
        </Button>
      ),
    },
  ];

  const table = (
    <TableComponent
      columns={columns}
      name="details"
      loading={selectorRedux.loading}
      data={selectorRedux.data?.results}
      metadata={selectorRedux.data?.metadata?.resultset || {}}
      handleUpdateFilters={dispatchRedux.updateFilters}
    />
  );

  const sendAnalytics = (
    <BoxWhite mb={-2.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color="secondary.main" fontWeight={500} fontSize="16px">
          Análises
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ bgcolor: 'info.main' }}
          onClick={() => {
            dispatchRedux.open({});
          }}
        >
          Enviar arquivo para análise
        </Button>
      </Box>
    </BoxWhite>
  );

  return (
    <AppLayout title="Análises">
      <Box display="flex" flexDirection="column" gap={3}>
        {filters}
        {sendAnalytics}
        {table}
      </Box>
    </AppLayout>
  );
};

export default AnalyticsScreen;
