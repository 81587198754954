import { createReducer } from '@reduxjs/toolkit';

import initialState from './filters.initialState';

import { ACTION_TYPES } from './filters.constant';

const handleReset = () => initialState;

const handleUpdateFilters = (state, action) => ({
  lastFilter: state.currentFilter,
  currentFilter: {
    ...state.currentFilter,
    ...action.payload,
  },
});

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.UPDATE.FILTERS]: handleUpdateFilters,
});
