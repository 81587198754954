import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import auth from '@spot/shared-store/auth';

export default function useProtectedByAuth() {
  const selectorRedux = {
    signedIn: useSelector(auth.selector.selectSignedIn),
  };

  const { signedIn } = selectorRedux;

  useEffect(() => {
    if (!signedIn) {
      const variant = 'spotesg';
      const currenthref = window.location.href;

      const url = `${process.env.REACT_APP_URL_SPOTAUTH}/login?redirectUrl=${currenthref}&variant=${variant}`;

      window.location.href = url;
    }
  }, [signedIn]);
}
