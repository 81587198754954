import { ACTION_TYPES } from './config.constant';

export const updateTab = (payload) => ({
  type: ACTION_TYPES.UPDATE.TAB,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
