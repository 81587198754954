import { all } from 'redux-saga/effects';

import auth from '@spot/shared-store/auth';
import router from '@spot/shared-store/router';

import login from '@spot-spotesg/modules/login/store';
import analytics from '@spot-spotesg/modules/analytics/store';

import configs from './configs';

import dashboard from '@spot-spotesg/modules/dashboard/store';
import profile from '@spot-spotesg/modules/profile/store';

function* rootSaga() {
  yield all([
    login.saga.watch(),
    analytics.saga.watch(),
    auth.saga.watch(),
    router.saga.watch(),
    configs.saga.watch(),
    dashboard.saga.watch(),
    profile.saga.watch(),
  ]);
}

export default rootSaga;
