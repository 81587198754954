import { all } from 'redux-saga/effects';

import mapDualRight from './mapDualRight';
import mapDualLeft from './mapDualLeft';

function* watch() {
  yield all([mapDualRight.saga.watch(), mapDualLeft.saga.watch()]);
}

export default {
  watch,
};
