import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import DASHBOARD_ROUTES from './constants/dashboardRoutes.constant';

import useProtectedByAuth from '@spot-spotesg/hooks/useProtectedByAuth.hook';

import DashboardScreen from './screens/Dashboard.screen';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';

import * as dashboardAction from './store/dashboard.action';

import useAuth from '@spot/shared-hooks/useAuth.hook';

const DashboardModule = () => {
  const { signedIn } = useAuth();

  useProtectedByAuth();

  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (signedIn) {
      dispatch(dashboardAction.serviceGetAll());
    }
  });

  return (
    <Switch>
      <Route exact path={DASHBOARD_ROUTES.ROOT} component={DashboardScreen} />
    </Switch>
  );
};

export default DashboardModule;
