import reducer from './analytics.reducer';
import * as selector from './analytics.selector';
import * as constant from './analytics.constant';
import * as action from './analytics.action';
import subscriber from './analytics.subscriber';
import saga from './analytics.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
  action,
};
