import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import {
  Box,
  Divider,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';

import toDateHourUtil from '@spot-spotesg/utils/toDateHour.util';

import BoxAnalyticsComponent from '../components/BoxAnalytics.component';
import MapComplianceComponent from '../components/MapCompliance.component';

import ROUTES from '@spot-spotesg/constants/routes.constant';

import PrintLayout from '../layouts/Print.layout';

import analyse from '../store/analyse';
import print from '../store/print';

const BoxA4 = ({ children, loading = false }) => {
  return (
    <Box
      width="210mm"
      height="297mm"
      bgcolor="white"
      position="relative"
      className="boxa4"
    >
      {children}

      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={9999}
          bgcolor="white"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const AnalyticsIdPrintScreen = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { id: idRouter }: any = useParams();

  const dispatchRedux = {
    serviceGet: flow(analyse.action.serviceGet, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(analyse.selector.selectLoading),
    data: useSelector(analyse.selector.selectData),
    dataPrint: useSelector(print.selector.selectState),
  };

  const loadingAllArr = [selectorRedux.loading];

  const loadingAll = loadingAllArr.some((e) => !!e);

  useEffectOnce(() => {
    setTimeout(() => {
      const tempTitle = document.title;
      document.title = `relatorio.pdf`;
      window.print();
      document.title = tempTitle;
    }, 2000);
  });

  // useEffectOnce(() => {
  //   if (idRouter) {
  //     dispatchRedux.serviceGet(idRouter);
  //   }
  // });

  const infos = (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={5}>
      {[
        {
          label: 'ID da análise',
          amount: selectorRedux?.data?.data?.id,
        },
        {
          label: 'Data da análise',
          amount: toDateHourUtil(selectorRedux?.data?.data?.finished_at),
        },
        {
          label: 'Data de criação',
          amount: toDateHourUtil(selectorRedux?.data?.data?.created_at),
        },
        {
          label: 'Status',
          amount: selectorRedux?.data?.data?.status,
        },
        {
          label: 'Descrição',
          amount: selectorRedux?.data?.data?.description,
          gridColumn: 'span 4',
        },
      ].map((v, index) => (
        <BoxAnalyticsComponent key={`box-operation-${index}`} {...v} />
      ))}
    </Box>
  );

  const buttonBack = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Box>
        <Button
          onClick={() => {
            if (history.length <= 2) {
              history.push(ROUTES.ANALYTICS.ID(idRouter));
            } else {
              history.goBack();
            }
          }}
          startIcon={<ArrowBackIcon />}
          sx={{ color: 'info.main' }}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );

  return (
    <PrintLayout>
      <Box display="flex" flexDirection="column" gap={3}>
        {buttonBack}

        <BoxA4 loading={loadingAll}>
          <Box display="flex" flexDirection="column" p={3} gap={3}>
            {infos}

            <Box height="298px">
              <MapComplianceComponent
                geom={selectorRedux?.dataPrint?.geom}
                layers={selectorRedux?.dataPrint?.layers}
                printMode
              />
            </Box>
          </Box>
        </BoxA4>
      </Box>
    </PrintLayout>
  );
};

export default AnalyticsIdPrintScreen;
