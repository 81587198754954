import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import format from 'date-fns/format';
import {
  Box,
  Divider,
  Typography,
  Button,
  CircularProgress,
  GlobalStyles,
} from '@mui/material';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';

import MapValidationComponent from '../components/MapDualValidation.component';
import LogoComponent from '@spot-spotesg/components/Logo.component';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ROUTES from '@spot-spotesg/constants/routes.constant';

import PrintLayout from '../layouts/Print.layout';

import toDateUtil from '@spot-spotesg/utils/toDate.util';

import print from '../store/print';
import mapDualLeft from '../store/mapDual/mapDualLeft';
import mapDualRight from '../store/mapDual/mapDualRight';

const BoxA4 = ({ children, loading = false }) => {
  return (
    <Box
      width="297mm"
      height="210mm"
      bgcolor="white"
      position="relative"
      className="boxa4"
    >
      {children}

      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={9999}
          bgcolor="white"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const AnalyticsValidationPrintScreen = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { id: idRouter }: any = useParams();

  const selectorRedux = {
    data: useSelector(print.selector.selectState),
  };

  const dispatchRedux = {
    resetBoundsLeft: flow(mapDualLeft.action.resetBounds, dispatch),
    resetBoundsRight: flow(mapDualRight.action.resetBounds, dispatch),
  };

  const buttonBack = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
          onClick={() => {
            if (history.length <= 2) {
              history.push(ROUTES.ANALYTICS.ID(idRouter));
            } else {
              history.goBack();
            }
          }}
          startIcon={<ArrowBackIcon />}
          sx={{ color: 'info.main' }}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const tempTitle = document.title;
            document.title = `relatorio.pdf`;
            window.print();
            document.title = tempTitle;
          }}
          sx={{ color: 'white' }}
        >
          Imprimir
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <GlobalStyles
        styles={{
          '@page': {
            margin: '16px',
            size: 'landscape',
          },
          '@media print': {
            'html, body': {
              height: '100%',
              overflow: 'hidden',
            },
          },
        }}
      />

      <PrintLayout orientation="landscape">
        <Box display="flex" flexDirection="column" gap={3}>
          {buttonBack}

          <BoxA4>
            <Box
              display="flex"
              flexDirection="column"
              p={3}
              gap={3}
              sx={{ '@media print': { p: 0, mt: -10 } }}
            >
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box display="flex">
                    <Typography variant="caption" fontSize="10px">
                      Satélite:{' '}
                      {selectorRedux.data?.left?.satellite_obj?.description}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="caption" fontSize="10px">
                      Data: {toDateUtil(selectorRedux.data?.left?.date)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box display="flex">
                    <Typography variant="caption" fontSize="10px">
                      Satélite:{' '}
                      {selectorRedux.data?.right?.satellite_obj?.description}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="caption" fontSize="10px">
                      Data: {toDateUtil(selectorRedux.data?.right?.date)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box height="520px">
                {selectorRedux.data?.geom && (
                  <MapValidationComponent
                    geom={selectorRedux.data?.geom}
                    areaSelected={selectorRedux.data?.areaSelected}
                    activeSync={false}
                    printMode
                  />
                )}
              </Box>

              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box>
                      <LogoComponent />
                    </Box>
                    <Typography variant="caption" fontSize="8px">
                      DIREITOS RESERVADOS LEI 5772 DE 21/12/71 A SPOTSAT. <br />{' '}
                      Reprodução ou execução no seu todo ou parte deste projeto,
                      sem a prévia autorização de seu autor, ficará sujeita as
                      medidas legais cabíveis. As alterações deste projeto
                      eximem o autor de quaisquer responsabilidades posteriores.
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignSelf="end"
                  >
                    <Typography variant="caption" fontSize="8px">
                      SISTEMA DE REFERÊNCIA: DATUM SIRGAS2000 <br />
                      COORDENADAS GEOGRÁFICAS <br />
                      FOLHA: A4 - ESCALA: 1:0.000 <br />
                      DATA: {format(new Date(), 'MM/yyyy')}
                    </Typography>
                    <Typography variant="caption" fontSize="8px">
                      Fontes: <br />
                      CAR- Cadastro Ambiental Rural <br />
                      INPE - PRODES - 2008/2021 <br />
                      IBAMA - Áreas Embargadas - 2021 <br />
                      FUNAI - Terra Indígena - 2021 <br />
                      Unidades de Conservação - 2021
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Box display="grid" gridTemplateColumns={'1fr 1fr'}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        width="16px"
                        height="16px"
                        bgcolor="rgb(51, 136, 255)"
                        sx={{
                          '@media print': {
                            WebkitPrintColorAdjust: 'exact',
                          },
                        }}
                      />
                      <Typography variant="caption">Área</Typography>
                    </Box>

                    {selectorRedux.data?.areaSelected?.map((as) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                        key={as?.id}
                      >
                        <Box
                          width="16px"
                          height="16px"
                          bgcolor="red"
                          sx={{
                            '@media print': {
                              WebkitPrintColorAdjust: 'exact',
                            },
                          }}
                        />
                        <Typography variant="caption">
                          {`${as?.geojson?.properties?.name} - ${as?.geojson?.properties?.year}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  {selectorRedux.data?.conclusion && (
                    <Box display="flex" flexDirection="column" mt={1}>
                      <Typography variant="caption" fontSize="10px">
                        Observação:
                      </Typography>
                      <Typography variant="caption" fontSize="14px">
                        {selectorRedux.data?.conclusion}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </BoxA4>
        </Box>
      </PrintLayout>
    </>
  );
};

export default AnalyticsValidationPrintScreen;
