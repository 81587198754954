export const NAME = 'totals';

const CONTEXT = 'DASHBOARD::TOTALS';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const SERVICE = {
  GET: SERVICE_GET,
};

const RESET = {
  STATE: RESET_STATE,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  SERVICE,
};
