import reducer from './totals.reducer';
import * as constant from './totals.constant';
import * as action from './totals.action';
import * as selector from './totals.selector';
import saga from './totals.saga';
import initialState from './totals.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
