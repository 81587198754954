import React, { Fragment } from 'react';
import { flow } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  getBottomNavigationUtilityClass,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkIcon from '@mui/icons-material/Link';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import useAuthHook from '@spot/shared-hooks/useAuth.hook';

import ROUTES from '@spot-spotesg/constants/routes.constant';

import DrawerNotificationComponent from '@spot-spotesg/components/DrawerNotification.component';
import LogoComponent from '@spot-spotesg/components/Logo.component';
import BoxWhite from '@spot-spotesg/components/Box/BoxWhite.component';

import misc from '@spot-spotesg/store/configs/misc';
import auth from '@spot/shared-store/auth';
import drawerNotification from '@spot-spotesg/store/configs/drawers/drawerNotification';

import ThemeProvider from '@spot-spotesg/providers/Theme.provider';

import toDateYear from '@spot-spotesg/utils/toDateYear.util';

const AppLayout = ({
  children,
  title,
  breadcrumb,
}: {
  children: any;
  title: any;
  breadcrumb?: any;
}) => {
  const { user: userAuth } = useAuthHook();

  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };

  const { pathname } = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const dispatchRedux = {
    unsetToken: flow(auth.action.unsetToken, dispatch),
    updateMode: flow(misc.action.updateMode, dispatch),
    open: flow(drawerNotification.action.open, dispatch),
  };

  const { mode } = selectorRedux;

  const menusObj = [
    {
      label: 'Dashboard',
      slug: ROUTES.DASHBOARD.ROOT.replace('/', ''),
      icon: <HomeOutlinedIcon />,
    },
    {
      label: 'Análises',
      slug: ROUTES.ANALYTICS.ROOT.replace('/', ''),
      icon: <BarChartOutlinedIcon />,
    },
    {
      label: 'Configurações',
      slug: ROUTES.PROFILE.ROOT.replace('/', ''),
      icon: <SettingsIcon />,
    },
    // {
    //   label: 'Helpdesk',
    //   slug: 'helpdesk',
    //   icon: <CampaignOutlinedIcon />,
    //   disabled: true,
    // },
  ];

  const menus = menusObj.map((m) => m.label);

  const currentModule = pathname.split('/')[1];

  const currentModuleObj: any = menusObj.find((m) => m.slug === currentModule);

  const sidebar = (
    <Box
      display="flex"
      flexDirection="column"
      px="32px"
      py="24px"
      gap="24px"
      position="sticky"
      top="16px"
    >
      <Box alignSelf="center" height="32px">
        <LogoComponent variant="spoteye" width="100" />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap={0.5}>
        <Avatar src={undefined} sx={{ width: 40, height: 40 }} />
        <Box alignSelf="center" height="27px">
          <img src={process.env.PUBLIC_URL + '/img/brasil.png'} alt="brasil" />
        </Box>
        <Typography variant="subtitle2" color="info.500" fontWeight={900}>
          {userAuth?.user?.name}
        </Typography>
        <Typography variant="bodyxs" color="gray.400" fontWeight={500}>
          {userAuth?.user?.email}
        </Typography>
      </Box>
      <Box mx={'-16px'}>
        <nav>
          <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {menus.map((m, idx) => (
              <ListItem disablePadding key={m}>
                <ListItemButton
                  onClick={() => history.push(`/${menusObj[idx].slug}`)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    color={
                      menusObj[idx].slug === currentModule
                        ? 'primary.main'
                        : 'gray.400'
                    }
                  >
                    {menusObj[idx]?.icon}

                    <Typography
                      variant="subtitle1"
                      color={
                        menusObj[idx].slug === currentModule
                          ? 'primary.main'
                          : 'gray.400'
                      }
                      fontWeight={
                        menusObj[idx].slug === currentModule ? 900 : 500
                      }
                    >
                      {m}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disablePadding>
              <ListItemButton onClick={() => dispatchRedux.unsetToken()}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  color="gray.400"
                >
                  <LogoutOutlinedIcon />
                  <Typography
                    variant="subtitle1"
                    color="gray.400"
                    fontWeight={500}
                  >
                    Sair
                  </Typography>
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </Box>
  );

  const head = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" gap={1} alignItems="center" color="white">
        {title && currentModuleObj.icon}

        {breadcrumb?.length > 0 && (
          <Box display="flex" gap={1}>
            {breadcrumb?.map((b, index) => (
              <Fragment key={`breadcrumb-${index}`}>
                {index === 0 && (
                  <Box display="flex" alignItems="center" gap={1} color="white">
                    {currentModuleObj.icon}
                    <Typography
                      variant="subtitle1"
                      color="white"
                      fontWeight={600}
                    >
                      {b}
                    </Typography>
                  </Box>
                )}

                {index !== 0 && <ChevronRightIcon />}

                {index > 0 && (
                  <Box display="flex" alignItems="center" gap={1} color="white">
                    <Typography
                      variant="subtitle1"
                      color="white"
                      fontWeight={600}
                    >
                      {b}
                    </Typography>
                  </Box>
                )}
              </Fragment>
            ))}
          </Box>
        )}

        {title && (
          <Typography color="white" variant="h3">
            {title}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={3}>
        <Box display="flex" alignItems="center">
          <Box display="flex" gap={0.5} alignItems="center">
            <DarkModeIcon style={{ color: 'white' }} />
            <Typography variant="bodyxs" color="white" fontWeight={700}>
              modo escuro
            </Typography>
          </Box>
          <Switch
            checked={mode === 'dark'}
            onChange={(ev, checked) =>
              dispatchRedux.updateMode(checked ? 'dark' : 'light')
            }
          />
        </Box>
        {
          <Box
            sx={{
              '.MuiBadge-badge': {
                bgcolor: 'error.main',
                color: 'white',
                fontSize: '12px',
                fontWeight: 700,
              },
            }}
          >
            <IconButton onClick={() => dispatchRedux.open('')}>
              <Badge badgeContent={0} color="primary">
                <NotificationsIcon sx={{ color: 'white' }} />
              </Badge>
            </IconButton>
          </Box>
        }
      </Box>
    </Box>
  );

  const footer = (
    <Box>
      <BoxWhite>
        <Box>
          <Box>
            <Typography color="gray.400" variant="subtitle2" textAlign="center">
              <strong>SpotESG</strong> - SpotSat LTDA© {toDateYear()} - Todos os
              direitos reservados
            </Typography>
          </Box>
        </Box>
      </BoxWhite>
    </Box>
  );

  return (
    <ThemeProvider>
      <Box position="relative">
        <Box
          position="absolute"
          width="100%"
          height="160px"
          top={0}
          left={0}
          bgcolor={mode === 'dark' ? '#1e370a' : 'primary.main'}
          zIndex={-1}
        />

        <Box
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          bgcolor={mode === 'dark' ? 'rgba(50, 50, 50, 0.5)' : '#F6F6F9'}
          zIndex={-2}
        />

        <Box
          display="grid"
          gridTemplateColumns="220px 1fr"
          minHeight="100vh"
          p="40px"
          gap="40px"
        >
          <Box
            bgcolor={mode === 'dark' ? '#121212' : 'background.default'}
            borderRadius="4px"
            boxShadow="0px 4px 8px rgba(0, 33, 82, 0.08)"
          >
            {sidebar}
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            {head}
            <Box>{children}</Box>
          </Box>
        </Box>
        <Box display="grid" gridTemplateColumns="100% 1fr" px="40px" pb="40px">
          {footer}
        </Box>
      </Box>

      <DrawerNotificationComponent />
    </ThemeProvider>
  );
};

export default AppLayout;
