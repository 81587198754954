import { takeLatest, call, put, delay } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';
import auth from '@spot/shared-store/auth';

import postApiAuthService from '@spot/shared-services/user/postApiAuth.service';

import login from '.';

const mock = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiODIzNzcyZTktOWRkMS00NzI4LThiMjAtNGIyODViYWE0ZmEwIiwibmFtZSI6IiIsImVtYWlsIjoiY2hyaXN0aWFuQGdpcm9wYWdhbWVudG9zLmNvbS5iciIsImNvZGVzIjp7ImlkUm9sZSI6MywiaWRVc2VyIjoyMjMsInNlZ21lbnQiOiI0LDUiLCJwb3J0YWRvciI6MSwiZWMiOjEsImlkQ2xpZW50IjoxMDA0fX0sImlhdCI6MTY1ODg0NDQ3MywiZXhwIjoxNjU4ODczMjczLCJpc3MiOiJHaXJvUGF5QHBkdi1vbmx5In0.AxHhT3CVL5HFZ8-hGg2ar3NQo1oKqMoQBopRnulngrM',
};

function* handleServicePost({ payload }: any) {
  yield put(login.action.fetchStart());

  const [success, result] = yield call(postApiAuthService, payload);

  if (!success) {
    yield put(
      toaster.action.show({ message: result?.message, variant: 'error' })
    );

    return yield put(login.action.fetchError(result));
  }

  const { access: token } = result;

  yield put(auth.action.setToken(token));

  return yield put(login.action.fetchEnd(result));
}

function* watch() {
  yield takeLatest(login.constant.ACTION_TYPES.SERVICE.POST, handleServicePost);
}

export default {
  watch,
};
