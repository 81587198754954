import reducer from './details.reducer';
import * as constant from './details.constant';
import * as action from './details.action';
import * as selector from './details.selector';
import saga from './details.saga';
import initialState from './details.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
