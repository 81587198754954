import { combineReducers } from 'redux';

import details from './details';
import totals from './totals';
import summary from './summary';

const reducer = combineReducers({
  [summary.name]: summary.reducer,
  [totals.name]: totals.reducer,
  [details.name]: details.reducer,
});

export default reducer;
