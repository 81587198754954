import React, { PureComponent } from 'react';
import { useTheme } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import orderBy from 'lodash/orderBy';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import misc from '@spot-spotesg/store/configs/misc';
import { useSelector } from 'react-redux';

const data = [
  { name: '', value: 0 },
  { name: '', value: 0 },
  { name: '', value: 0 },
];

export default function ChartPieReferenceComponent({ data: dataProp }) {
  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };
  const { mode } = selectorRedux;
  if (!dataProp) {
    dataProp = [];
  }

  const theme: any = useTheme();

  const COLORS = [
    '#64dd17',
    '#ffd54f',
    '#00b8d4',
    '#f44336',
    '#7c4dff',
    '#009688',
    '#75a7ff',
    '#ff8a65',
    '#4db6ac',
    '#90a4ae',
  ];

  const dataParsed = dataProp.map((a) => {
    return { description: a.name, value: a.count };
  });

  return (
    <Box display="flex" gap={1} alignItems="left" flexDirection="column">
      {dataParsed.length > 0 &&
        orderBy(dataParsed, 'value', 'desc').map((a, index) => (
          <Box key={a?.description} display="flex" alignItems="center" gap={1}>
            <Box
              width={12}
              height={12}
              bgcolor={COLORS[index]}
              borderRadius="50%"
            />

            <Typography variant="subtitle2" color="gray.400">
              {a?.description} <strong>{`(${a.value})`}</strong>
            </Typography>
          </Box>
        ))}
    </Box>
  );
}
