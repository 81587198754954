import React, { useEffect, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { flow } from 'lodash';
import { useDispatch } from 'react-redux';

import PROFILE_ROUTES from './constants/profileRoutes.constant';

import useProtectedByAuth from '@spot-spotesg/hooks/useProtectedByAuth.hook';

import ProfileScreen from './screens/Profile.screen';
import ProfileEditUserScreen from './screens/ProfileEditUser.screen';

import config from './store/config';

const ProfileModule = () => {
  useProtectedByAuth();

  const dispatch = useDispatch();

  const dispatchRedux = {
    resetState: flow(config.action.resetState, dispatch),
  };

  React.useEffect(() => {
    dispatchRedux.resetState();
  }, []);

  return (
    <Fragment>
      <Switch>
        <Route exact path={PROFILE_ROUTES.ROOT} component={ProfileScreen} />
      </Switch>
    </Fragment>
  );
};

export default ProfileModule;
