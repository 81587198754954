import reducer from './satellites.reducer';
import * as constant from './satellites.constant';
import * as action from './satellites.action';
import * as selector from './satellites.selector';
import saga from './satellites.saga';
import initialState from './satellites.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
