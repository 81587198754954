import React from 'react';

const LogoComponent = (props) => {
  return (
    <img
      src={process.env.PUBLIC_URL + '/img/logo-spotesg.png'}
      alt="logo"
      {...props}
    />
  );
};

export default LogoComponent;
