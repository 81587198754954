import reducer from './mapDual.reducer';
import * as selector from './mapDual.selector';
import * as constant from './mapDual.constant';
import subscriber from './mapDual.subscriber';
import saga from './mapDual.saga';

export default {
  reducer,
  constant,
  selector,
  subscriber,
  saga,
  name: constant.NAME,
};
