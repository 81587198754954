import reducer from './print.reducer';
import * as selector from './print.selector';
import * as constant from './print.constant';
import * as action from './print.action';

export default {
  reducer,
  constant,
  selector,
  action,
  name: constant.NAME,
};
