import React from 'react';
import {
  Switch as Routes,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useSelector, useDispatch } from 'react-redux';

import useNotification from '@spot/shared-hooks/useNotification.hook';

import LoginModule from './modules/login/Login.module';
import DashboardModule from './modules/dashboard/Dashboard.module';
import AnalyticsModule from './modules/analytics/Analytics.module';

import ROUTES from './constants/routes.constant';

import auth from '@spot/shared-store/auth';

import useEffectOnce from './hooks/useEffectOnce.hook';
import ProfileModule from './modules/profile/Profile.module';

const RoutesModule = () => {
  const dispatch = useDispatch();

  const signedIn = useSelector(auth.selector.selectSignedIn);

  const { pathname } = useLocation();

  useNotification();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffectOnce(() => {
    if (process.env.REACT_APP_LOGROCKET === 'true') {
      setupLogRocketReact(LogRocket);

      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID || '');
    }
  });

  return (
    <>
      <Routes>
        <Route path={ROUTES.LOGIN.ROOT} component={LoginModule} />
        <Route path={ROUTES.DASHBOARD.ROOT} component={DashboardModule} />
        <Route path={ROUTES.ANALYTICS.ROOT} component={AnalyticsModule} />
        <Route path={ROUTES.PROFILE.ROOT} component={ProfileModule} />
        <Redirect to={ROUTES.DASHBOARD.ROOT} />
      </Routes>
    </>
  );
};

export default RoutesModule;
