import React from 'react';
import { Drawer, Box, Typography, TextField, IconButton } from '@mui/material';
import { LoadingButton as Button } from '@mui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { useDropzone } from 'react-dropzone';

import ThemeProvider from '@spot-spotesg/providers/Theme.provider';

import CloudUpload from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopy from '@mui/icons-material/ContentCopy';

import dialogSendAnalytics from '../store/dialogs/dialogSendAnalytics';

const DrawerSendAnalyticsComponent = () => {
  const [type, setType] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [file, setFile] = React.useState<any>(null);

  const dispatch = useDispatch();

  const dispatchRedux = {
    close: flow(dialogSendAnalytics.action.close, dispatch),
    servicePost: flow(dialogSendAnalytics.action.servicePost, dispatch),
  };

  const selectorRedux = {
    open: useSelector(dialogSendAnalytics.selector.selectVisible),
    loading: useSelector(dialogSendAnalytics.selector.selectLoading),
  };

  React.useEffect(() => {
    if (selectorRedux.open === true) {
      setType('');
      setDescription('');
      setFile(null);
    }
  }, [selectorRedux.open]);

  const onDrop = React.useCallback(async (files: any) => {
    setFile(files);
  }, []);

  const dropzone = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <Drawer
      anchor={'right'}
      open={selectorRedux.open || false}
      onClose={() => {
        dispatchRedux.close();
      }}
    >
      <ThemeProvider>
        <Box width={600} p={4} display="flex" flexDirection="column" gap={3}>
          <Box>
            <img src="/img/sendAnalyticsIcon.png" />
          </Box>
          <Typography variant="h3" fontWeight={700} color="secondary.main">
            Enviar arquivos para análise
          </Typography>
          <TextField
            label="Tipo de arquivo"
            fullWidth
            select
            value={type}
            SelectProps={{ native: true }}
            onChange={({ target: { value } }) => setType(value)}
          >
            <option value=""></option>
            <option value="KML">KML</option>
            <option value="KMZ">KMZ</option>
            <option value="GEOJSON">GEOJSON</option>
          </TextField>
          <TextField
            value={description}
            label="Descrição (Opcional)"
            minRows={3}
            multiline
            onChange={({ target: { value } }) => setDescription(value)}
          />

          {file === null && (
            <Box
              border="1px dashed transparent"
              borderColor="gray.200"
              p={2}
              borderRadius={0.5}
              {...dropzone.getRootProps()}
            >
              <Box
                bgcolor="gray.100"
                borderRadius={0.5}
                display="flex"
                flexDirection="column"
                gap={1}
                height="260px"
                justifyContent="center"
              >
                <Box alignSelf="center">
                  <Box component={CloudUpload} sx={{ color: '#9CA1AA' }} />
                </Box>
                <Typography align="center" variant="subtitle2" color="gray.400">
                  Arraste e solte aqui
                </Typography>
                <Typography align="center" variant="subtitle2" color="gray.400">
                  ou
                </Typography>
                <Box alignSelf="center">
                  <Button variant="contained" color="secondary">
                    Selecionar arquivo
                  </Button>
                  <input
                    {...dropzone.getInputProps()}
                    capture
                    style={{ display: 'none' }}
                  />
                </Box>
                <Typography align="center" variant="subtitle2" color="gray.300">
                  Formatos permitidos: KML, KMZ e GEOSON (.json).
                </Typography>
                <Typography align="center" variant="subtitle2" color="gray.300">
                  Tamanho máxmo de de carregamento 10MB.
                </Typography>
              </Box>
            </Box>
          )}

          {file && (
            <Box
              border="1px dashed transparent"
              borderColor="gray.200"
              p={2}
              borderRadius={0.5}
            >
              <Box
                bgcolor="gray.100"
                borderRadius={0.5}
                display="flex"
                flexDirection="column"
                gap={1}
                height="260px"
                justifyContent="center"
                position="relative"
              >
                <Box position="absolute" top={0} right={0}>
                  <IconButton size="small" onClick={() => setFile(null)}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Box alignSelf="center">
                  <ContentCopy />
                </Box>
                <Typography align="center">{file[0]?.name}</Typography>
              </Box>
            </Box>
          )}

          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => {
                dispatchRedux.close();
              }}
            >
              Cancelar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={file === null || type === ''}
              loading={selectorRedux.loading}
              sx={{ color: 'white' }}
              onClick={() => {
                dispatchRedux.servicePost({
                  type,
                  description,
                  file: file[0],
                });
              }}
            >
              Enviar
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    </Drawer>
  );
};

export default DrawerSendAnalyticsComponent;
