import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import useDialogHook from '@spot-spotesg/hooks/useDialogRedux.hook';

import messagesSchema from '@spot/shared-constants/messagesSchema.constant';

import FieldLabelFormikComponent from '@spot-spotesg/components/FieldLabelFormik.component';

import dialogChangeEmail from '../store/dialogs/dialogChangePassword';

import ThemeProvider from '@spot-spotesg/providers/Theme.provider';

export default function DialogForgotPassword() {
  const dispatch = useDispatch();
  const { handleClose, visible } = useDialogHook(dialogChangeEmail);

  const dispatchRedux = {
    servicePost: flow(dialogChangeEmail.action.servicePost, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(dialogChangeEmail.selector.selectLoading),
    variant: useSelector(dialogChangeEmail.selector.selectVariant),
    data: useSelector(dialogChangeEmail.selector.selectData),
  };

  const schema = Yup.object({
    email: Yup.string()
      .email(messagesSchema.EMAIL)
      .required(messagesSchema.REQUIRED),
  });

  const initialValues = {
    email: '',
  };

  const initial = (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => dispatchRedux.servicePost(values)}
      validateOnMount
      validationSchema={schema}
    >
      {({ errors, submitForm }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            submitForm();
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            p={4}
            sx={{ background: 'white' }}
            gap={1}
          >
            <Box alignSelf="center">
              <img src={process.env.PUBLIC_URL + '/img/lock.png'} alt="lock" />
            </Box>
            <Typography variant="h4" color="info.dark2" align="center">
              Esqueceu a senha?
            </Typography>
            <Typography variant="subtitle1" color="gray.400" align="center">
              Não se preocupe, digite o <strong>email cadastrado</strong> e
              enviaremos um link para você recuperá-la!
            </Typography>
            <Box mt={2}>
              <FieldLabelFormikComponent
                name="email"
                fullWidth
                label="E-mail"
                size="small"
              />
            </Box>
            <Box alignSelf="flex-end" display="flex" gap={3} mt={3}>
              <Button
                onClick={handleClose}
                color="primary"
                sx={{
                  bgcolor: 'gray.200',
                  color: 'info.dark2',
                  fontSize: '16px',
                  fontWeight: '700',
                  width: 108,
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                onClick={submitForm}
                variant="contained"
                color="primary"
                loading={selectorRedux.loading}
                disabled={Object.keys(errors).length > 0}
                sx={{
                  bgcolor: 'success.main',
                  color: 'info.dark2',
                  fontSize: '16px',
                  fontWeight: '700',
                  width: 108,
                }}
              >
                Enviar
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );

  const success = (
    <Box
      display="flex"
      flexDirection="column"
      p={4}
      sx={{ background: 'white' }}
      gap={3}
    >
      <Box alignSelf="center">
        <img src={process.env.PUBLIC_URL + '/img/email.png'} alt="lock" />
      </Box>
      <Typography variant="body2" color="gray.black" align="center">
        Enviamos um email para redefinir a sua senha para o email{' '}
        <strong>{selectorRedux?.data?.email}</strong>!
      </Typography>
      <Typography variant="body2" color="gray.black" align="center">
        Verifique a sua caixa de entrada e spam.
      </Typography>
      <Box alignSelf="flex-end" display="flex" gap={3} mt={3}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{
            bgcolor: 'gray.200',
            color: 'info.dark2',
            fontSize: '16px',
            fontWeight: '700',
            width: 108,
          }}
        >
          Fechar
        </Button>
      </Box>
    </Box>
  );

  return (
    <ThemeProvider>
      <Dialog
        open={visible}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': { backgroundColor: 'transparent', maxWidth: 450 },
        }}
      >
        <Box position="relative">
          <Box position="absolute" top={16} right={16} zIndex={999}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {selectorRedux.variant === 'initial' && initial}
          {selectorRedux.variant === 'finish' && success}
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
