import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  begin_date: string;
  end_date: string;
};

export default async function getApiDashboardsSummaryService(filters: Filters) {
  const filtersStringfy = qs.stringify(filters, { skipNulls: true });

  const url = ['dashboards/summary', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'DASHBOARD_ESG',
    auth: true,
  });
}
