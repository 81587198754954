import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiSatellitesService() {
  return fetch('satellites', {
    method: 'GET',
    ms: 'TEMPORAL',
    auth: true,
  });
}
