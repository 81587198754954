export const NAME = 'config';

const CONTEXT = 'PROFILE::CONFIG';

const UPDATE_TAB = `${CONTEXT}::UPDATE::TAB`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const UPDATE = {
  TAB: UPDATE_TAB,
};

const RESET = {
  STATE: RESET_STATE,
};

export const ACTION_TYPES = {
  UPDATE,
  RESET,
};
