import { ACTION_TYPES } from './mapDualLeft.constant';

export const servicePost = (payload) => ({
  type: ACTION_TYPES.SERVICE.POST,
  payload,
});

export const serviceGetMapId = (payload) => ({
  type: ACTION_TYPES.SERVICE.GET_MAP_ID,
  payload,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const resetBounds = () => ({
  type: ACTION_TYPES.RESET.BOUNDS,
});

export const updateFilters = (payload) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  payload,
});

export const updateTile = (payload) => ({
  type: ACTION_TYPES.UPDATE.TILE,
  payload,
});

export const updateBounds = (payload) => ({
  type: ACTION_TYPES.UPDATE.BOUNDS,
  payload,
});

export const updateDraw = (payload) => ({
  type: ACTION_TYPES.UPDATE.DRAW,
  payload,
});
