import { combineReducers } from 'redux';

import drawers from './dialogs';
import analyse from './analyse';
import analysis from './analysis';
import filters from './filters';
import print from './print';
import totals from './totals';
import mapDual from './mapDual';
import satellites from './satellites';

const reducer = combineReducers({
  [drawers.name]: drawers.reducer,
  [print.name]: print.reducer,
  [filters.name]: filters.reducer,
  [mapDual.name]: mapDual.reducer,
  [totals.name]: totals.reducer,
  [analysis.name]: analysis.reducer,
  [analyse.name]: analyse.reducer,
  [satellites.name]: satellites.reducer,
});

export default reducer;
