const ROUTES = {
  ROOT: '/profile',
  EDIT_USER: (id = '') =>
    id ? `/profile/edit-user/${id}` : '/profile/edit-user/:id',
};

const PROFILE_ROUTES = {
  ROOT: ROUTES.ROOT,
  EDIT_USER: ROUTES.EDIT_USER,
};

export const NAMESPACE = ROUTES.ROOT;

export default PROFILE_ROUTES;
