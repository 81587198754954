import reducer from './users.reducer';
import * as constant from './users.constant';
import * as action from './users.action';
import * as selector from './user.selector';
import saga from './users.saga';
import initialState from './users.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
