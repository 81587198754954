import flow from 'lodash/flow';
import { takeLatest, select, call, put, take } from 'redux-saga/effects';

import auth from '@spot/shared-store/auth';
import toaster from '@spot/shared-store/toaster';

import currentState from '.';

import users from '../users';

const handleServiceGetUserId = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    serviceGetUserId: flow(users.action.serviceGetId, put),
    resetState: flow(users.action.resetState, put),
  };

  const user = yield select(auth.selector.selectUser);

  try {
    yield handlers.fetchStart();

    yield handlers.serviceGetUserId({
      user_id: user?.user_id,
    });

    while (true) {
      const success = users.constant.ACTION_TYPES.FETCH.END;
      const error = users.constant.ACTION_TYPES.FETCH.ERROR;

      const action = yield take([error, success]);

      const { type, payload } = action;

      if (type === error) {
        yield handlers.resetState();

        throw payload;
      }

      if (type === success) {
        yield handlers.resetState();

        return yield handlers.fetchEnd(payload);
      }
    }
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePatch = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    servicePatch: flow(users.action.servicePatch, put),
    resetState: flow(users.action.resetState, put),
  };

  const user = yield select(auth.selector.selectUser);

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    yield handlers.servicePatch({
      user_id: user?.user_id,
      ...payload,
    });

    while (true) {
      const success = users.constant.ACTION_TYPES.FETCH.END;
      const error = users.constant.ACTION_TYPES.FETCH.ERROR;

      const action = yield take([error, success]);

      const { type, payload } = action;

      if (type === error) {
        yield handlers.resetState();

        throw payload;
      }

      if (type === success) {
        yield handlers.resetState();

        return yield handlers.fetchEnd(payload);
      }
    }
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_USER_ID,
    handleServiceGetUserId
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );
}

export default {
  watch,
};
