import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  email: string;
};

export default async function postApiUsersResetpasswordService(
  payload: Payload
) {
  const url = ['users', 'reset-password'].join('/');

  return fetch(url, {
    method: 'POST',
    ms: 'USER',
    body: JSON.stringify(payload),
  });
}
