import * as React from 'react';
import flow from 'lodash/flow';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { Typography, Divider, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import drawerNotification from '@spot-spotesg/store/configs/drawers/drawerNotification';

const BoxNotification = ({ label, amount, icon, bgColorIcon }) => {
  return (
    <Box display="flex" gap={2} alignItems="start">
      <Box
        p={1}
        bgcolor={bgColorIcon}
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={1}
      >
        {icon}
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography fontSize="14px" fontWeight={600} color="gray.400">
          {label}
        </Typography>
        <Typography fontSize="14px" fontWeight={500} color="gray.400">
          {amount}
        </Typography>
      </Box>
    </Box>
  );
};

export default function DrawerNotificationComponent() {
  const dispatch = useDispatch();

  const selectorRedux = {
    open: useSelector(drawerNotification.selector.selectVisible),
  };

  const dispatchRedux = {
    close: flow(drawerNotification.action.close, dispatch),
    open: flow(drawerNotification.action.open, dispatch),
  };

  return (
    <Drawer
      anchor={'right'}
      open={selectorRedux.open || false}
      onClose={() => dispatchRedux.close()}
    >
      <Box
        width={350}
        p={'40px 20px'}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize="16px" fontWeight={700}>
            Notificações
          </Typography>

          <IconButton onClick={() => dispatchRedux.close()}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        <Box display="flex" flexDirection="column" gap={3}>
          <BoxNotification
            label="Não há notificações no momento"
            amount=""
            icon=""
            bgColorIcon=""
          />
        </Box>
      </Box>
    </Drawer>
  );
}
