import { createReducer } from '@reduxjs/toolkit';

import initialState from './print.initialState';

import { ACTION_TYPES } from './print.constant';

const handleReset = () => initialState;

const handleUpdatePrint = (state, action) => action.payload;

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.UPDATE.PRINT]: handleUpdatePrint,
});
