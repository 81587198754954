import { isEqual } from 'lodash';
import flow from 'lodash/flow';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';

import postApiAnalysisService from '@spot/shared-services/analysis_esg/postApiAnalysis.service';

import currentStore from '.';

import * as analyticsAction from '../../analytics.action';

function* handleReset() {
  const state = yield select(currentStore.selector.selectState);

  if (isEqual(currentStore.initialState, state)) {
    return true;
  }

  yield delay(500);

  yield put(currentStore.action.reset());
}

function* handleServicePost(action) {
  const handlers = {
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield put(currentStore.action.fetchStart());

    const [success, result] = yield call(postApiAnalysisService, payload);

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(payload));

    yield handlers.show({
      message: 'Arquivo carregado com sucesso',
      variant: 'success',
    });

    yield put(currentStore.action.close());

    yield put(analyticsAction.serviceGetAll());
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield put(currentStore.action.fetchError());
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );

  yield takeLatest(currentStore.constant.ACTION_TYPES.SET.CLOSE, handleReset);
}

export default {
  watch,
};
