import { all } from 'redux-saga/effects';

import details from './details';
import totals from './totals';
import summary from './summary';

function* watch() {
  yield all([summary.saga.watch(), totals.saga.watch(), details.saga.watch()]);
}

export default {
  watch,
};
