import LOGIN_ROUTES from '@spot-spotesg/modules/login/constants/loginRoutes.constant';
import DASHBOARD_ROUTES from '@spot-spotesg/modules/dashboard/constants/dashboardRoutes.constant';
import ANALYTICS_ROUTES from '@spot-spotesg/modules/analytics/constants/analyticsRoutes.constant';
import PROFILE_ROUTES from '@spot-spotesg/modules/profile/constants/profileRoutes.constant';

const NAMESPACE = '/';
const NAMESPACE_LOGIN = LOGIN_ROUTES.ROOT;
const NAMESPACE_ANALYTICS = ANALYTICS_ROUTES.ROOT;
const NAMESPACE_DASHBOARD = DASHBOARD_ROUTES.ROOT;
const NAMESPACE_PROFILE = PROFILE_ROUTES.ROOT;

export const NAMESPACES = {
  ROOT: NAMESPACE,
  LOGIN: NAMESPACE_LOGIN,
  ANALYTICS: NAMESPACE_ANALYTICS,
  DASHBOARD: NAMESPACE_DASHBOARD,
  PROFILE: NAMESPACE_PROFILE,
};

const ROUTES = {
  ROOT: NAMESPACE,
  LOGIN: LOGIN_ROUTES,
  PROFILE: PROFILE_ROUTES,
  ANALYTICS: ANALYTICS_ROUTES,
  DASHBOARD: DASHBOARD_ROUTES,
};

export default ROUTES;
