import reducer from './filters.reducer';
import * as selector from './filters.selector';
import * as constant from './filters.constant';
import * as action from './filters.action';

export default {
  reducer,
  constant,
  selector,
  action,
  name: constant.NAME,
};
