import { createReducer } from '@reduxjs/toolkit';

import initialState from './config.initialState';

import { ACTION_TYPES } from './config.constant';

const handleReset = () => initialState;

const handleUpdateTab = (state, action) => {
  state.tab = action.payload;
};

export default createReducer(initialState, {
  [ACTION_TYPES.UPDATE.TAB]: handleUpdateTab,
  [ACTION_TYPES.RESET.STATE]: handleReset,
});
