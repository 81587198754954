import React from 'react';
import { TextFieldProps } from '@mui/material';
import { TextField as TextFieldFormik } from 'formik-mui';
import { Field } from 'formik';

const TextFieldLabelFormikComponent = (
  props: TextFieldProps & { name: string }
) => {
  const { name, ...restProps } = props;

  return (
    <Field
      name={name}
      component={TextFieldFormik}
      variant="outlined"
      {...restProps}
    />
  );
};

export default TextFieldLabelFormikComponent;
