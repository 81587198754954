import { ACTION_TYPES } from './dashboard.constant';

export const serviceGetAll = () => ({
  type: ACTION_TYPES.SERVICE.GET_ALL,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const changePage = () => ({
  type: ACTION_TYPES.CHANGE,
});

export const mountPage = () => ({
  type: ACTION_TYPES.MOUNT,
});

export const unmountPage = () => ({
  type: ACTION_TYPES.UNMOUNT,
});
