import React from 'react';
import { Router } from 'react-router-dom';
import { StylesProvider } from '@mui/styles';
import { SnackbarProvider } from 'notistack';

import { Provider } from 'react-redux';

import ReactDOM from 'react-dom/client';

import getHistory from '@spot/shared-utils/getHistory.util';

import reportWebVitals from './reportWebVitals';
import RoutesModule from './routes';
import { useStore } from './store';

import 'react-datepicker/dist/react-datepicker.css';
import 'leaflet/dist/leaflet.css';
import 'swiper/css';

const App = () => {
  const store = useStore();

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <SnackbarProvider>
          <Router history={getHistory()}>
            <RoutesModule />
          </Router>
        </SnackbarProvider>
      </StylesProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
