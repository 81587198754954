import { all } from 'redux-saga/effects';

import dialogChangePassword from './dialogSendAnalytics';

function* watch() {
  yield all([dialogChangePassword.saga.watch()]);
}

export default {
  watch,
};
