import React, { PureComponent } from 'react';
import { useTheme } from '@mui/styles';
import monthConstant from '../constants/month.constant';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import misc from '@spot-spotesg/store/configs/misc';
import { useSelector } from 'react-redux';
import toDecimal from '@spot-spotesg/utils/toDecimal.util';

export default function ChartBarComponent({ data: dataProp }) {
  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };

  const { mode } = selectorRedux;

  const dataParsed = dataProp?.analysis_period?.reduce(function (memo, x) {
    if (!memo[x['month']]) {
      memo[x['month']] = [];
    }
    memo[x['month']].push(x);
    return memo;
  }, {});
  const dataParsedFinal: any = [];
  dataParsed
    ? Object.values(dataParsed).forEach((value, index, arr: any[]) => {
        const success: any = arr[0].find((obj: any) => {
          return obj.status === 'success';
        });
        const divergent: any = arr[0].find((obj: any) => {
          return obj.status === 'divergent';
        });
        dataParsedFinal.push({
          name: monthConstant?.find((m) => m.nome === success?.month)?.sigla,
          Sucesso: success?.count ? Number(success?.count) : 0,
          Divergente: divergent?.count ? Number(divergent?.count) : 0,
        });
      })
    : [];
  const theme: any = useTheme();

  return (
    <ResponsiveContainer width="95%" aspect={0} height="100%">
      <BarChart
        width={500}
        height={300}
        data={dataParsedFinal}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0 0" />
        <XAxis dataKey="name" tick={{ fontSize: 16 }} interval={0} />
        <YAxis
          domain={[
            'dataMin',
            (dataMax) => Math.ceil((dataMax * 1.3) / 10) * 10,
          ]}
        />
        <Tooltip />
        <Bar dataKey="Sucesso" stackId="x" fill="#498ef4">
          {dataParsedFinal?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill="#498ef4" />
          ))}
        </Bar>
        <Bar dataKey="Divergente" stackId="x" fill="#f65f54">
          {dataParsedFinal?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill="#f65f54" />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
