import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  satellite: string;
  image_id: string;
};

export default async function getApiMapsSatelliteImagesImageIdService(
  filters: Filters
) {
  return fetch(
    `maps/${encodeURIComponent(filters.satellite)}/images/${encodeURIComponent(
      filters.image_id
    )}`,
    {
      method: 'GET',
      ms: 'TEMPORAL',
      auth: true,
    }
  );
}
