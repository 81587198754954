import { all } from 'redux-saga/effects';

import drawers from './dialogs';
import analyse from './analyse';
import analysis from './analysis';
import totals from './totals';
import mapDual from './mapDual';
import satellites from './satellites';

function* watch() {
  yield all([
    drawers.saga.watch(),
    analyse.saga.watch(),
    analysis.saga.watch(),
    mapDual.saga.watch(),
    totals.saga.watch(),
    satellites.saga.watch(),
  ]);
}

export default {
  watch,
};
