import { all } from 'redux-saga/effects';

import user from './currentUser';
import users from './users';

function* watch() {
  yield all([users.saga.watch(), user.saga.watch()]);
}

export default {
  watch,
};
