import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  token?: string;
  email_confirmed?: string;
  photo?: string;
};

export default async function patchApiAccountsIdService(id, payload: Payload) {
  const url = ['accounts', `${id}`].join('/');

  const formData = new FormData();

  const { token, ...restPayload } = payload;

  Object.keys(restPayload).map((p) => formData.append(p, payload[p]));

  return fetch(url, {
    method: 'PATCH',
    ms: 'ACCOUNT',
    body: formData,
    defaultHeaders: false,
    auth: !token,
    ...Object.assign(
      {},
      token && {
        headers: {
          Authorization: token,
        },
      }
    ),
  });
}
