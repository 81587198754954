export const NAME = 'print';

const CONTEXT = 'ANALYSIS::PRINT';

const UPDATE_PRINT = `${CONTEXT}::UPDATE::PRINT`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const RESET = {
  STATE: RESET_STATE,
};

const UPDATE = {
  PRINT: UPDATE_PRINT,
};

export const ACTION_TYPES = {
  RESET,
  UPDATE,
};
