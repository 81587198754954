const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  filters: {
    offset: 0,
    limit: 20,
  },
};

export default initialState;
