import { combineReducers } from 'redux';

import mapDualRight from './mapDualRight';
import mapDualLeft from './mapDualLeft';

const reducer = combineReducers({
  [mapDualLeft.name]: mapDualLeft.reducer,
  [mapDualRight.name]: mapDualRight.reducer,
});

export default reducer;
